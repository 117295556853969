import { GET_ALLUSERS, SET_CURRENT_USER } from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setError } from "./errorActions";
import { constants } from "../utils/constants";

export const getUsers = () => (dispatch: any) => {
  axios
    .get(`${constants.NEW_API_URL}/v1/user/`)
    .then((res: any) => {
      dispatch({
        type: GET_ALLUSERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setError(err.response.data));
      }
    });
};

export const setCurrentUser = (decoded: any) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const login =
  (userData: { username: string; password: string }) => (dispatch: any) => {
    axios
      .post(`${constants.AUTH_URL}/auth/login`, userData)
      .then((res: any) => {
        const { token } = res.data;
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded));
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setError(err.response.data));
        }
      });
  };

export const logout = () => (dispatch: any) => {
  axios.get(`${constants.AUTH_URL}/auth/logout`);
  //Set auth header
  setAuthToken(false as any);
  dispatch(setCurrentUser({}));
};
