import React, { useEffect, useState } from "react";
import axios from "axios";
import base64 from "base-64";
import { constants } from "../utils/constants";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export interface WithPermissionProps {
  isRoleValid: boolean;
  isFetchingPerm: boolean;
  permissionsErrorMsg: string;
}

export const withPermissions = (
  WrappedComponent: any,
  permission: string,
  redirect = true
) => {
  const LoadingUserData = ({ ...props }) => {
    const [isRoleValid, setIsRoleValid] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");

    const roleName = useSelector((state: any) => state.user.userInfo.roles);
    const navigate = useNavigate();
    useEffect(() => {
      axios
        .post(`${constants.AUTH_URL}/auth/verifyRole`, {
          permission: permission,
          roleName: roleName,
        })
        .then((_) => setIsRoleValid(true))
        .catch((err) => {
          setIsRoleValid(false);
          if (err.response) {
            setErrorMsg(err.response.data.msg);
          }
        })
        .finally(() => {
          setIsFetching(false);

          if (!isFetching && !isRoleValid && redirect) {
            navigate(
              `/403${
                errorMsg.length > 0 ? "?msg=" + base64.encode(errorMsg) : ""
              }`
            );
          }
        });
    });

    return (
      <WrappedComponent
        isRoleValid={isRoleValid}
        isFetchingPerm={isFetching}
        permissionsErrorMsg={errorMsg}
        {...props}
      />
    );
  };

  return LoadingUserData;
};
