import React, { Component, createRef } from "react";
import classNames from "classnames";

type Props = {
  addOffset?: number;
  className?: Array<string>;
};

class FadeInScrollContainer extends Component<Props> {
  private containerRef = createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener("scroll", this.checkSlide);
  }

  checkSlide = (e: any) => {
    let container = this.containerRef.current;

    if (container !== null) {
      const slideInAt =
        window.scrollY +
        window.innerHeight -
        container.getBoundingClientRect().height / 3 +
        container.getBoundingClientRect().height / 3;
      const isHalfShown =
        slideInAt > container.offsetTop;

      if (isHalfShown) {
        container.classList.add("fadeInController__in");
      }

      /*let containerTop = container.getBoundingClientRect().top;

      if (
        document.documentElement.scrollTop >=
        containerTop + container.getBoundingClientRect().top
      ) {
        container.classList.add("fadeInController__in");
      }*/
    }
  };

  render() {
    return (
      <div
        className={`fadeInController ${classNames(
          this.props.className ? [...this.props.className] : ""
        )}`}
        ref={this.containerRef}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FadeInScrollContainer;
