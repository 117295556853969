import React, { FunctionComponent, MouseEvent } from "react";
import { ProjectsDataInterface } from "../../../interfaces/Projects";

interface Props {
  project: ProjectsDataInterface;
  counter: number;
  mouseEnter: (e: MouseEvent<HTMLDivElement>) => void;
  mouseLeave: (e: MouseEvent<HTMLDivElement>) => void;
  click: (e: MouseEvent<HTMLDivElement>) => void;
  openPopUp: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ProjectCard: FunctionComponent<Props> = ({
  project,
  counter,
  mouseEnter,
  mouseLeave,
  click,
  openPopUp
}) => (
  <div
    className="projectCard"
    onMouseEnter={mouseEnter}
    onMouseLeave={mouseLeave}
    onClick={click}
  >
    <div
      className="bg-image"
      style={{ backgroundImage: `url(${project.showcaseImage})` }}
    />
    <div className="projectCard__container">
      <div className="projectCard__heading">
        <div>
          <h2 className="projectCard__heading--h2">{project.name}</h2>
          <div
            className={`u-seperator ${counter % 2 === 0 ? "even" : "odd"}`}
          />
        </div>
        <div className="projectCard__heading--previewLink">
          <i className="fa fa-link" />
          <a href={project.urls[0]} target="_blank" rel="noopener noreferrer">
            {project.urls[0]}
          </a>
        </div>
      </div>
      <p>{project.shortDescription}</p>
      <button
        className={`projectCard__learnMore ${
          counter % 2 === 0 ? "even" : "odd"
        }`}
        data-id={project._id}
        onClick={openPopUp}
      >
        Learn More
      </button>
    </div>
  </div>
);

export default ProjectCard;
