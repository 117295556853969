import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import remarkGemoji from "remark-gemoji";

import { getAbout, setAbout } from "../../../../../actions/contentActions";
import AboutWrap from "./AboutWrap";
import { useRoleVerification } from "../../../../useRoleVerification";
import { useNavigate } from "react-router-dom";

export const About: FunctionComponent = () => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const aboutData = useSelector((state: any) => state.content.about);
  const { data: roleData, isLoading: roleLoading } = useRoleVerification(
    "jmWeb:about:post",
    true
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAbout());
  }, [dispatch]);

  const onClickSaveButton = () => {
    let editor = editorRef.current;

    if (!editor) return;

    dispatch(setAbout(editor.innerHTML.toString()));
    setShowSuccess(true);
  };

  const onClickAbortButton = () => {
    navigate("/admin/website");
  };

  const onCloseSuccess = () => {
    setShowSuccess(false);
  };

  return (
    <>
      {roleData && !roleLoading && (
        <AboutWrap
          onSave={onClickSaveButton}
          onAbort={onClickAbortButton}
          editorRef={editorRef}
          showSuccess={showSuccess}
          onClose={onCloseSuccess}
        >
          <ReactMarkdown remarkPlugins={[remarkGemoji]}>
            {aboutData}
          </ReactMarkdown>
        </AboutWrap>
      )}
    </>
  );
};
