import React, { Component } from "react";
import { connect } from "react-redux";
import { Message } from "../../interfaces";
import classNames from "classnames";

import { getMessages } from "../../actions/messagesActions";
import isEmpty from "../../functions/isEmpty";

interface StateToProps {
  messages: Array<Message>;
}

interface DispatchToProps {
  getMessages: () => void;
}

interface Props {
  ownClassNames: Array<string>;
}

type ReduxProps = StateToProps & DispatchToProps;

class NotificationCounter extends Component<ReduxProps & Props, {}> {
  state = {
    messagesCount: 0
  };

  componentDidMount() {
    this.props.getMessages();
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (!isEmpty(nextProps.messages)) {
      let messagesCount: number = 0;
      // eslint-disable-next-line
      nextProps.messages.map((el: any) => {
        if (!el.isRead) {
          messagesCount++;
        }
      });
      this.setState({ messagesCount });
    }
  }

  render() {
    const { messagesCount } = this.state;
    const { ownClassNames } = this.props;

    return (
      <React.Fragment>
        {messagesCount > 0 && messagesCount ? (
          <div className={classNames(...ownClassNames)}>
            <span>{messagesCount > 0 ? messagesCount : 0}</span>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ messages }: any) => ({
  messages
});

export default connect(mapStateToProps, { getMessages })(NotificationCounter);
