import React, { FunctionComponent } from "react";
import WebsiteNav from "../WebsiteNav";
import { Route, Navigate, Routes, useLocation } from "react-router-dom";
import SubMenu from "./SubMenu";

interface Props {
  layoutType: string;
}

export const Website: FunctionComponent<Props> = ({ layoutType }) => {
  const location = useLocation();

  return (
    <div>
      {layoutType === "desktop" ? <WebsiteNav /> : null}
      <Routes>
        <Route path={":name"} element={<SubMenu />} />
        {layoutType === "desktop" ? (
          <Route path="" element={<h3>Please select a topic.</h3>} />
        ) : (
          <Navigate to={`${location.pathname}/about`} />
        )}
      </Routes>
    </div>
  );
};
