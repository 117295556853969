const prod = {
  API_URL: "https://joel.muehlena.de",
  NEW_API_URL: "https://api.joel.muehlena.de",
  AUTH_URL: "https://auth.joel.muehlena.de",
  ASSETS_URL: "https://assets.joel.muehlena.de",
  BLOG_URL: "https://blog.joel.muehlena.de",
  BASE_URL: "https://joel.muehlena.de",
  ADMIN_URL: "https://joel.muehlena.de/admin",
};

const dev = {
  API_URL: "http://172.25.100.113:3002",
  NEW_API_URL: "http://172.25.100.113:8080",
  ASSETS_URL: "http://192.168.0.127:3004",
  AUTH_URL: "http://172.25.100.113:3003",
  BLOG_URL: "http://172.25.100.113:3009",
  BASE_URL: "http://172.25.100.113:3000",
  ADMIN_URL: "http://172.25.100.113:3000/admin",
};

const stage = {
  API_URL: "https://api.joel.muehlena.staging.de",
  NEW_API_URL: "https://api.joel.muehlena.de",
  AUTH_URL: "https://auth.joel.muehlena.staging.de",
  ASSETS_URL: "https://assets.joel.muehlena.staging.de",
  BLOG_URL: "https://blog.joel.muehlena.staging.de",
  BASE_URL: "https://joel.muehlena.staging.de",
  ADMIN_URL: "https://joel.muehlena.staging.de/admin",
};

let toExport = dev;

if (process.env.NODE_ENV === "production") {
  toExport = prod;

  if ((window as any).REACT_APP_IS_STAGING === true) {
    toExport = stage;
  }
}

export const constants = toExport;
