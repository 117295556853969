import axios from "axios";
import {
  GET_ABOUT_TEXT,
  GET_SKILLS,
  GET_EDUCATION,
  SET_ERROR,
  GET_EXPERIENCE,
  SET_LOADING,
  SET_SAVING,
  GET_PROJECTS,
  GET_PROJECTS_BY_ID,
} from "./types";

import { constants } from "../utils/constants";
import { Skill } from "../interfaces/Skills";

//const apiKey = "HR5MYPtgzMYY6oEtMkQcse7aRBVWrlsPFMFemUx2";

export const setLoading = (state: boolean) => ({
  type: SET_LOADING,
  payload: state,
});

export const setSaving = (state: boolean) => ({
  type: SET_SAVING,
  payload: state,
});

export const getAbout = () => (dispatch: any) => {
  axios
    .get(`${constants.NEW_API_URL}/v1/about/`)
    .then((res: any) => {
      dispatch({
        type: GET_ABOUT_TEXT,
        payload: res.data.data,
      });
    })
    .catch(
      (err) => console.log(err)
      /* dispatch({
        type: SET_ERROR,
        payload: err.response.data
      }) */
    );
};

export const setAbout = (data: any) => (dispatch: any) => {
  axios
    .post(`${constants.NEW_API_URL}/v1/about/`, {
      text: data,
    })
    .then(() => dispatch(getAbout()))
    .catch(
      (err) => console.log(err)
      /* dispatch({
        type: SET_ERROR,
        payload: err.response.data
      }) */
    );
};

// Skills
export const getSkills = () => (dispatch: any) => {
  axios
    .get(`${constants.NEW_API_URL}/v2/skill/`)
    .then((res: any) => {
      dispatch(setLoading(true));
      dispatch({
        type: GET_SKILLS,
        payload: res.data.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setSkills =
  (
    skillGroupName: string,
    skillData: { skills: Array<Skill> },
    update: boolean
  ) =>
  (dispatch: any) => {
    if (update) {
      axios
        .put(`${constants.NEW_API_URL}/v2/skill/`, {
          skillGroupName,
          skillData,
        })
        .then(() => dispatch(getSkills()))
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`${constants.NEW_API_URL}/v2/skill/`, {
          skillGroupName,
          skillData,
        })
        .then(() => dispatch(getSkills()))
        .catch((err) => console.log(err));
    }
  };

export const delSkills = (skillGroupId: string) => (dispatch: any) => {
  axios
    .delete(`${constants.NEW_API_URL}/v2/skill/${skillGroupId}`)
    .then(() => dispatch(getSkills()))
    .catch((err) => console.log(err));
};

export const getEducation = () => (dispatch: any) => {
  axios
    .get(`${constants.NEW_API_URL}/v1/education/`)
    .then((res: any) =>
      dispatch({
        type: GET_EDUCATION,
        payload: res.data.data,
      })
    )
    .catch(
      (err) => console.log(err)
      /* dispatch({
        type: SET_ERROR,
        payload: err.response.data
      }) */
    );
};

export const setEducation =
  (
    id: string,
    schoolName: string,
    description: string,
    schoolLocation: string,
    from: number,
    to: number,
    graduated: string,
    current: boolean,
    isNew: boolean
  ) =>
  (dispatch: any) => {
    if (isNew) {
      axios
        .post(`${constants.NEW_API_URL}/v1/education/`, {
          schoolName,
          schoolLocation,
          description,
          from,
          to,
          current,
          graduated: parseInt(graduated),
        })
        .then((_) => dispatch(getEducation()))
        .catch((err) =>
          dispatch({
            type: SET_ERROR,
            payload: err.response.data,
          })
        );
    } else {
      axios
        .put(`${constants.NEW_API_URL}/v1/education/${id}/`, {
          schoolName,
          schoolLocation,
          description,
          from,
          to,
          current,
          graduated: parseInt(graduated),
        })
        .then((_) => dispatch(getEducation()))
        .catch((err) =>
          dispatch({
            type: SET_ERROR,
            payload: err.response.data,
          })
        );
    }
  };

export const delEducation = (id: string) => (dispatch: any) => {
  axios
    .delete(`${constants.NEW_API_URL}/v1/education/${id}/`)
    .then((_) => dispatch(getEducation()))
    .catch((err) => console.log(err));
};

export const getExperience = () => (dispatch: any) => {
  axios
    .get(`${constants.NEW_API_URL}/v1/experience/`)
    .then((res: any) =>
      dispatch({
        type: GET_EXPERIENCE,
        payload: res.data.data,
      })
    )
    .catch(
      (err) => console.log(err)
      /* dispatch({
        type: SET_ERROR,
        payload: err.response.data
      }) */
    );
};

export const delExperience = (id: string) => (dispatch: any) => {
  axios
    .delete(`${constants.NEW_API_URL}/v1/experience/${id}/`)
    .then(() => dispatch(getExperience()))
    .catch((err) => console.log(err));
};

export const setExperience =
  (
    id: string,
    companyName: string,
    description: string,
    companyLocation: string,
    from: Date,
    to: Date,
    current: boolean,
    title: string,
    type: number,
    update: boolean
  ) =>
  (dispatch: any) => {
    if (!update) {
      axios
        .post(`${constants.NEW_API_URL}/v1/experience/`, {
          companyName,
          title,
          companyLocation,
          description,
          from,
          to,
          current,
          type,
        })
        .then(() => dispatch(getExperience()))
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: SET_ERROR,
              payload: err.response.data,
            });
          }
        });
    } else {
      axios
        .put(`${constants.NEW_API_URL}/v1/experience/${id}/`, {
          companyName,
          title,
          companyLocation,
          description,
          from,
          to,
          current,
          type,
        })
        .then(() => dispatch(getExperience()))
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: SET_ERROR,
              payload: err.response.data,
            });
          }
        });
    }
  };

export const getProjects = () => (dispatch: any) => {
  axios
    .get(`${constants.NEW_API_URL}/v1/project/`)
    .then((res: any) =>
      dispatch({
        type: GET_PROJECTS,
        payload: res.data.data,
      })
    )
    .catch((err) => {
      console.log(err);
      if (err.response) {
        console.log(err.response);
        dispatch({
          type: SET_ERROR,
          payload: err.response.data,
        });
      }
    });
};

export const getProjectById = (id: string) => (dispatch: any) => {
  axios
    .get(`${constants.NEW_API_URL}/v1/project/${id}/`)
    .then((res: any) =>
      dispatch({
        type: GET_PROJECTS_BY_ID,
        payload: res.data.data,
      })
    )
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: SET_ERROR,
          payload: err.response.data,
        });
      }
    });
};

export const setProjects =
  (
    name: string,
    shortDescription: string,
    description: string,
    showcaseImage: string,
    images: string,
    from: number,
    to: number,
    current: boolean,
    urls: string,
    skills: string,
    id: string,
    update: boolean
  ) =>
  (dispatch: any) => {
    if (!update) {
      axios
        .post(`${constants.NEW_API_URL}/v1/project/`, {
          name,
          shortDescription,
          description,
          showcaseImage,
          images,
          from,
          to,
          current,
          urls,
          skills,
        })
        .then(() => dispatch(getProjects()))
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: SET_ERROR,
              payload: err.response.data,
            });
          }
        });
    } else {
      axios
        .put(`${constants.NEW_API_URL}/v1/project/${id}/`, {
          name,
          shortDescription,
          description,
          showcaseImage,
          images,
          from,
          to,
          current,
          urls,
          skills,
        })
        .then(() => dispatch(getProjects()))
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: SET_ERROR,
              payload: err.response.data,
            });
          }
        });
    }
  };

export const delProject = (id: string) => (dispatch: any) => {
  axios
    .delete(`${constants.NEW_API_URL}/v1/project/${id}/`)
    .then(() => {
      dispatch(setLoading(true));
      dispatch(getProjects());
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: SET_ERROR,
          payload: err.response.data,
        });
      }
    });
};
