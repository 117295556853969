import React, {
  Children,
  FunctionComponent,
  isValidElement,
  MouseEvent,
  ReactNode,
} from "react";

interface Props {
  children: ReactNode;
  state: "opened" | "closed";
  index: number;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
}

export const AccordionEntry: FunctionComponent<Props> = ({
  children,
  state,
  index,
  onClick,
}) => {
  return (
    <div data-index={index} className="jm-AccordionMenu__point">
      {Children.map(children, (child, i) => {
        if (i === 0 && isValidElement(child))
          return (
            <div
              onClick={onClick}
              data-index={index}
              className={`jm-AccordionMenu__pointHeader ${
                state === "opened" ? "open" : "close"
              }`}
            >
              {child}
            </div>
          );

        if (i === 1 && isValidElement(child))
          return (
            <div
              className={`jm-AccordionMenu__pointContent ${
                state === "opened" ? "open" : "close"
              }`}
            >
              {child}
            </div>
          );
      })}
    </div>
  );
};
