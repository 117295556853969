import React, {
  Children,
  FunctionComponent,
  isValidElement,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { AccordionEntry } from "./AccordionEntry";

import "./AccordionStyle.scss";

interface Props {
  children: ReactNode;
}

export const Accordion: FunctionComponent<Props> = ({ children }) => {
  const [editChildren, setEditChildren] = useState<ReactNode>();

  const [openIndex, setOpenIndex] = useState<number>(-1);

  const clickMenuPoint = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const target = e.currentTarget;
      let index = parseInt(target.dataset.index ?? "-1");

      if (index === -1 && openIndex === -1) return;

      index = index === openIndex ? -1 : index;

      setOpenIndex(index);
    },
    [openIndex]
  );

  useEffect(() => {
    const ec = Children.map(children, (menuPoint, i) => {
      if (
        isValidElement(menuPoint) &&
        Children.count(menuPoint.props.children) === 2
      ) {
        return (
          <AccordionEntry
            state={i === openIndex ? "opened" : "closed"}
            index={i}
            onClick={clickMenuPoint}
          >
            {menuPoint.props.children}
          </AccordionEntry>
        );
      } else {
        return null;
      }
    });

    setEditChildren(ec);
  }, [openIndex, children, clickMenuPoint]);

  return <div className="jm-AccordionMenu">{editChildren}</div>;
};
