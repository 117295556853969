import React, { Component, RefObject } from "react";
import { constants } from "../../utils/constants";
//import { constants } from "../../utils/constants";

interface LandingMenuOverlayProps {
  refMenu: RefObject<HTMLDivElement>;
  onentryclick: (e: any) => void;
}

class LandingMenuOverlay extends Component<LandingMenuOverlayProps, {}> {
  render() {
    return (
      <div className="jm_landingMenuOverlay" ref={this.props.refMenu}>
        <nav className="landingMenuOverlay__nav">
          <div
            className="landingMenuOverlay__nav--entry"
            onClick={this.props.onentryclick}
          >
            <a href="#start">Start</a>
          </div>
          <div
            className="landingMenuOverlay__nav--entry"
            onClick={this.props.onentryclick}
          >
            <a href="#about">About</a>
          </div>
          <div
            className="landingMenuOverlay__nav--entry"
            onClick={this.props.onentryclick}
          >
            <a href="#portfolio">Portfolio</a>
          </div>
          <div
            className="landingMenuOverlay__nav--entry"
            onClick={this.props.onentryclick}
          >
            <a href="#projects">Projects</a>
          </div>
          <div
            className="landingMenuOverlay__nav--entry"
            onClick={this.props.onentryclick}
          >
            <a href="#contact">Contact</a>
          </div>
          <div
            className="landingMenuOverlay__nav--entry"
            onClick={this.props.onentryclick}
          >
            <a href={constants.BLOG_URL}>Blog</a>
          </div>
        </nav>
      </div>
    );
  }
}

export default LandingMenuOverlay;
