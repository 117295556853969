import React, { Component, MouseEvent } from "react";
import { connect } from "react-redux";
import {
  getEducation,
  delEducation,
} from "../../../../../actions/contentActions";
import { educationData } from "../../../../../interfaces/Education";
import EducationWrapper from "./EducationWrapper";
import EditEducationController from "./EditEducationController";
import Modal from "../../../../common/Modal";
import { withPermissions } from "../../../../withPermissions";

interface DispatchToProps {
  getEducation: () => void;
  delEducation: (id: string) => void;
  educationData: Array<educationData>;
}

class Education extends Component<DispatchToProps, {}> {
  state = {
    showAddMenu: false,
    showEditMenu: false,
    showModal: false,
    delId: "",
    editId: "",
  };

  componentDidMount() {
    this.props.getEducation();
  }

  addNew = () => {
    this.setState({ showAddMenu: true });
  };

  editOne = (e: MouseEvent<HTMLButtonElement>) => {
    this.setState({
      showEditMenu: true,
      editId: e.currentTarget.dataset.id,
    });
  };

  cancelEdit = () => {
    this.setState({
      showEditMenu: false,
      showAddMenu: false,
      editId: "",
    });
  };

  deleteOne = (e: MouseEvent<HTMLButtonElement>) => {
    this.setState({
      delId: e.currentTarget.dataset.id,
      showModal: true,
    });
  };

  confirmDeleteOne = () => {
    this.props.delEducation(this.state.delId);
    this.abortDeleteOne();
  };

  abortDeleteOne = () => {
    this.setState({
      delId: "",
      showModal: false,
    });
  };

  render() {
    const { showAddMenu, showEditMenu, showModal, editId } = this.state;
    const { educationData } = this.props;

    return (
      <EducationWrapper addOne={this.addNew}>
        {showModal && (
          <Modal
            text="Do you really want to delete this entry?"
            onAbort={this.abortDeleteOne}
            onContinue={this.confirmDeleteOne}
          />
        )}

        {showAddMenu && (
          <EditEducationController
            id={"0"}
            data={educationData}
            resetWindow={this.cancelEdit}
          />
        )}

        {showEditMenu && (
          <EditEducationController
            id={editId}
            data={educationData}
            resetWindow={this.cancelEdit}
          />
        )}

        {this.props.educationData.length > 0 &&
          this.props.educationData.map((eduEntry, i) => (
            <div className="adminEducation__entry" key={i}>
              <h3>
                {eduEntry.schoolName} / {eduEntry.description}
              </h3>
              <div className="adminEducation__entry--dates">
                <p>{eduEntry.from}</p> &nbsp;
                <span className="m-dash-edu">&mdash;</span>&nbsp;
                {eduEntry.current ? <p>now</p> : <p>{eduEntry.to}</p>}
              </div>
              <div className="adminEducation__entry--buttons">
                <button
                  onClick={this.editOne}
                  data-id={eduEntry._id}
                  className="btn btn-midBlue"
                >
                  Edit
                </button>
                <button
                  onClick={this.deleteOne}
                  data-id={eduEntry._id}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
      </EducationWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    educationData: state.content.education,
  };
};

export default withPermissions(
  connect(mapStateToProps, { getEducation, delEducation })(Education),
  "jmWeb:education:get"
);
