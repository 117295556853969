import React, { Component, ChangeEvent } from "react";
import classNames from "classnames";

interface InputFieldProps {
  type: string;
  name: string;
  placeholder?: string;
  value?: string | number;
  label?: string;
  required?: boolean;
  error?: any;
  extraClasses?: any;
  disabled?: boolean;
  onchange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

class InputField extends Component<InputFieldProps, {}> {
  render() {
    return (
      <div className="formGroup">
        <input
          {...this.props}
          type={this.props.type}
          name={this.props.name}
          value={this.props.value}
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          className={classNames("inputField", this.props.extraClasses)}
          required={this.props.required}
          onChange={this.props.onchange}
          disabled={this.props.disabled || !this.props.disabled ? this.props.disabled : false}
        />
        {this.props.label && <label>{this.props.label}</label>}
        {this.props.error && (
          <small className="u-error-message-input">{this.props.error}</small>
        )}
      </div>
    );
  }
}

export default InputField;
