import React, { FunctionComponent } from "react";

const LoadingSpinner: FunctionComponent = () => {
  return (
    <div className="u-spinner-animate">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LoadingSpinner;
