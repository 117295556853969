import React, { Fragment, FunctionComponent, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { SkillGroup } from "../../../../../interfaces/Skills";
import { getSkills, setLoading } from "../../../../../actions/contentActions";

import { Accordion } from "../../../../common/AccordionMenu/Accordion";

import { useRoleVerification } from "../../../../useRoleVerification";

/*interface DispatchToProps {
  getSkills: () => void;
  setLoading: (state: boolean) => void;
  setSkills: (
    skillGroupName: string,
    skillData: { skills: Array<Skill> },
    update: boolean
  ) => void;
  delSkills: (skillGroupId: string) => void;
}*/

export const Skills: FunctionComponent = () => {
  const dispatch = useDispatch();
  const skillData: Array<SkillGroup> = useSelector(
    (state: any) => state.content.skills,
    shallowEqual
  );
  const { data: roleData, isLoading: roleLoading } = useRoleVerification(
    "jmWeb:skill:get",
    true
  );

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getSkills());
  });

  const addSkillEntry = () => {};

  return (
    <>
      {roleData && !roleLoading && (
        <div className="adminSkills">
          <Accordion>
            {skillData.map((skillPoint) => (
              <Fragment key={skillPoint._id}>
                <h2>{skillPoint.skillGroupName}</h2>
                <div className="adminSkills__entry">
                  {skillPoint.skills.map((skill, i) => (
                    <Fragment key={i}>
                      {skill.skillName} - {skill.level} -{" "}
                      {skill.skillDescription ?? "No description"}
                    </Fragment>
                  ))}
                </div>
              </Fragment>
            ))}
          </Accordion>

          <p
            className="btn-round btn-midBlue"
            style={{ position: "absolute", right: "2rem", bottom: "2rem" }}
            onClick={addSkillEntry}
          >
            <span>+</span>
          </p>
        </div>
      )}
    </>
  );
};
