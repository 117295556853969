import React, { Component, MouseEvent } from "react";
import ExperiencesWrapper from "./ExperiencesWrapper";
import { connect } from "react-redux";
import { ExperieceDataInterface } from "../../../../../interfaces/Experience";
import {
  getExperience,
  delExperience,
} from "../../../../../actions/contentActions";
import Modal from "../../../../common/Modal";
import EditExperienceController from "./EditExperienceController";
import { getMonthNameById } from "../../../../../utils/getMonthNames";
import { withPermissions } from "../../../../withPermissions";

interface DispatchToProps {
  getExperience: () => void;
  delExperience: (id: string) => void;
}

interface stateToProps {
  errors: any;
  experience: Array<ExperieceDataInterface>;
}

type ReduxProps = DispatchToProps & stateToProps;

class ExperiencesController extends Component<ReduxProps, any> {
  state = {
    showModal: false,
    showAddMenu: false,
    showEditMenu: false,
    editId: "",
    delId: "",
  };

  componentDidMount() {
    this.props.getExperience();
  }

  addOne = (e: any) => {
    this.setState({
      showAddMenu: true,
    });
  };

  editOne = (e: MouseEvent<HTMLButtonElement>) => {
    this.setState({
      showEditMenu: true,
      editId: e.currentTarget.dataset.id,
    });
  };

  deleteOne = (e: MouseEvent<HTMLButtonElement>) => {
    this.setState({
      showModal: true,
      delId: e.currentTarget.dataset.id,
    });
  };

  abortDeleteOne = () => {
    this.setState({
      showModal: false,
      delId: "",
    });
  };

  confirmDeleteOne = () => {
    this.props.delExperience(this.state.delId);
    this.abortDeleteOne();
  };

  cancelEdit = () => {
    this.setState({
      showEditMenu: false,
      showAddMenu: false,
      editId: "",
    });
  };

  render() {
    const { experience } = this.props;

    const { showAddMenu, showEditMenu, showModal, editId } = this.state;

    return (
      <ExperiencesWrapper addOne={this.addOne}>
        {showModal && (
          <Modal
            text="Do you really want to delete this entry?"
            onAbort={this.abortDeleteOne}
            onContinue={this.confirmDeleteOne}
          />
        )}

        {showAddMenu && (
          <EditExperienceController
            id={"0"}
            data={experience}
            resetWindow={this.cancelEdit}
          />
        )}

        {showEditMenu && (
          <EditExperienceController
            id={editId}
            data={experience}
            resetWindow={this.cancelEdit}
          />
        )}

        {experience.length > 0 &&
          experience.map((xpEntry, i) => (
            <div className="adminExperience__entry" key={i}>
              <h3>
                {xpEntry.companyName} / {xpEntry.title}
              </h3>
              <div className="adminExperience__entry--dates">
                <p>
                  {getMonthNameById(new Date(xpEntry.from).getMonth())}&nbsp;
                  {new Date(xpEntry.from).getFullYear()}
                </p>
                &nbsp;
                <span className="m-dash-edu">&mdash;</span>&nbsp;
                {xpEntry.current === false || xpEntry.current === undefined ? (
                  <p>
                    {getMonthNameById(new Date(xpEntry.to).getMonth())}&nbsp;
                    {new Date(xpEntry.to).getFullYear()}
                  </p>
                ) : (
                  <p>Now</p>
                )}
              </div>
              <div className="adminExperience__entry--buttons">
                <button
                  onClick={this.editOne}
                  data-id={xpEntry._id}
                  className="btn btn-midBlue"
                >
                  Edit
                </button>
                <button
                  onClick={this.deleteOne}
                  data-id={xpEntry._id}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
      </ExperiencesWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    errors: state.errors,
    experience: state.content.experience,
  };
};

export default withPermissions(
  connect(mapStateToProps, {
    getExperience,
    delExperience,
  })(ExperiencesController),
  "jmWeb:experience:get"
);
