import { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "./common/LoadingSpinner";
import { constants } from "../utils/constants";

const fetchCV = async () => {
  const cvUrl = `${constants.NEW_API_URL}/v2/cv-generator/`;
  return axios({
    url: cvUrl,
    method: "GET",
    withCredentials: false,
    responseType: "blob",
  })
    .then(({ data }: any) => {
      return new Blob([data], { type: "application/pdf" });
    })
    .catch((err) => {
      if (err.response) {
        throw Error(err.response);
      } else {
        throw Error(err);
      }
    });
};

export const MyCV = () => {
  const [cvPdf, setCvPdf] = useState<Blob | null>(null);
  const [cvFetchError, setCvFetchError] = useState(false);
  const [cvFetchErrorMsg, setCvFetchErrorMsg] = useState("");

  useEffect(() => {
    fetchCV()
      .then((data) => setCvPdf(data as Blob))
      .catch((error) => {
        setCvFetchError(true);
        if (error instanceof Error) {
          setCvFetchErrorMsg(error.message);
        }
      });
  }, []);

  const openPDF = () => {
    if (cvPdf !== null) {
      const fileUrl = URL.createObjectURL(cvPdf);

      window.open(fileUrl);
    }
  };

  return (
    <div className="jm-cvPage popUpWindow__content--cvContent">
      <p>The CV is now genereated dynamically for you</p>
      {cvPdf !== null && <button onClick={openPDF}>Open PDF</button>}
      {!cvFetchError && cvPdf === null && <LoadingSpinner />}
      {cvFetchError ? (
        <div className="errorDiv">
          <p>There was an error fetching the CV</p>
          <small>{cvFetchErrorMsg}</small>
          <button onClick={() => window.location.reload()}>Reload page</button>
        </div>
      ) : null}
    </div>
  );
};
