import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { setSuccessMsg } from "../../actions/feedbackMessagesActions";

interface StateToProps {
  successMsg: string;
}

interface mapDispatchToProps {
  setSuccessMsg: (msg: string) => void;
}

class SuccessMessage extends Component<StateToProps & mapDispatchToProps> {
  onClickCloseMsg = () => {
    this.props.setSuccessMsg("");
  };

  render() {
    return (
      <Fragment>
        {this.props.successMsg.length > 0 ? (
          <div className="feedbackMessage feedbackMessage__success">
            <div className="placeholder"></div>
            <p>{this.props.successMsg}</p>
            <i className="fa fa-times" onClick={this.onClickCloseMsg} />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    successMsg: state.feedbackMessages.successMsg,
  };
};

export default connect(mapStateToProps, { setSuccessMsg })(SuccessMessage);
