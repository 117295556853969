import React, { Component, MouseEvent } from "react";
import ReactMarkdown from "react-markdown";
import remarkGemoji from "remark-gemoji";

import { getAbout } from "../../../actions/contentActions";
import { connect } from "react-redux";
import AboutWrap from "./AboutWrap";
import FadeInScrollContainer from "../../common/FadeInScrollContainer";

interface DispatchToProps {
  getAbout: () => void;
  aboutData: string;
}

interface Props {
  openCVWindow: () => void;
}

interface StateAbout {
  showSkipAbout: boolean;
}

class About extends Component<DispatchToProps & Props, StateAbout> {
  state = {
    showSkipAbout: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.skipButtonChange);
    this.props.getAbout();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.skipButtonChange);
  }

  skipButtonChange = () => {
    let aboutSec = document.getElementById("about")!;

    let changeTo: boolean;
    if (
      window.scrollY > aboutSec.offsetTop + 600 &&
      window.scrollY < aboutSec.clientHeight + 200 &&
      window.innerWidth <= 970
    ) {
      changeTo = true;
    } else {
      changeTo = false;
    }

    this.setState({
      showSkipAbout: changeTo,
    });
  };

  onClickSVG = (event: MouseEvent<HTMLOrSVGElement>) => {
    let service: string = event.currentTarget.dataset.service!;
    /*if (event.target.tagName == "path") {
      service = event.target.parentElement.dataset.service;
    } else {
      service = event.target.dataset.service;
    }*/
    let url: string = "";
    switch (service) {
      case "twitter":
        url = "https://twitter.com/joel_muehlena";
        break;
      case "applemusic":
        url = "https://music.apple.com/profile/joel_muehlena";
        break;
      case "instagram":
        url = "https://www.instagram.com/joel_muehl/";
        break;
    }
    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: url,
    }).click();
  };

  render() {
    const { aboutData } = this.props;
    const { showSkipAbout } = this.state;

    return (
      <AboutWrap
        onClickSVG={this.onClickSVG}
        showSkipAbout={showSkipAbout}
        openCVWindow={this.props.openCVWindow}
      >
        <FadeInScrollContainer>
          <ReactMarkdown skipHtml={true} remarkPlugins={[remarkGemoji]}>
            {aboutData}
          </ReactMarkdown>
        </FadeInScrollContainer>
      </AboutWrap>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    aboutData: state.content.about,
  };
};

export default connect(mapStateToProps, { getAbout })(About);
