export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";

// Content Types
export const SET_LOADING = "SET_LOADING";
export const SET_SAVING = "SET_SAVING";

export const GET_ABOUT_TEXT = "GET_ABOUT_TEXT";
export const SET_ABOUT_TEXT = "SET_ABOUT_TEXT";
export const DEL_ABOUT_TEXT = "DEL_ABOUT_TEXT";

// Skills
export const GET_SKILLS = "GET_SKILLS";
export const ADD_SKILL = "ADD_SKILL";
export const DEL_SKILL = "DEL_SKILL";

// Education
export const GET_EDUCATION = "GET_EDUCATION";
export const GET_EDUCATION_BY_ID = "GET_EDUCATION_BY_ID";

// Projects
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_BY_ID = "GET_PROJECTS_BY_ID";

// Experience
export const GET_EXPERIENCE = "GET_EXPERIENCE";

// Messages Types
export const GET_ALLMESSAGES = "GET_ALLMESSAGES";

// Users Types
export const GET_ALLUSERS = "GET_ALLUSERS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CREDENTIAL_DATA_LOADING = "SET_CREDENTIAL_DATA_LOADING";
