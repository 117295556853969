import React, { FunctionComponent } from "react";

interface Props {
  addOne: (e: any) => void;
}

const ExperiencesWrapper: FunctionComponent<Props> = ({ children, addOne }) => {
  return (
    <div className="adminExperience">
      {children}
      <p className="btn-round btn-midBlue" onClick={addOne}>
        <span>+</span>
      </p>
    </div>
  );
};

export default ExperiencesWrapper;
