import React, { Component, ChangeEvent } from "react";
import { connect } from "react-redux";

import EditEducationWrapper from "./EditEducationWrapper";
import { setEducation } from "../../../../../actions/contentActions";
import { educationData } from "../../../../../interfaces/Education";
import { withPermissions } from "../../../../withPermissions";

interface Props {
  id: string;
  data: Array<educationData>;
  resetWindow: () => void;
}

interface DisptachToProps {
  setEducation: (
    id: string,
    schoolName: string,
    description: string,
    schoolLocation: string,
    from: number,
    to: number,
    graduated: string,
    current: boolean,
    isNew: boolean
  ) => void;
}

interface StateToProps {
  education: educationData;
  errors: any;
}

type ReduxProps = DisptachToProps & StateToProps;

class EditEducationController extends Component<Props & ReduxProps, any> {
  state = {
    saveEntry: false,
    data: undefined,
    name: "",
    description: "",
    location: "",
    from: 0,
    to: 0,
    graduated: "",
    current: false,
    nextErrors: {},
  };

  componentDidMount() {
    const { id, data } = this.props;

    let filterDataForId: Array<educationData> = data.filter(
      (el) => el._id === id
    );

    if (filterDataForId.length > 0) {
      let dts: educationData = filterDataForId[0];
      this.setState({
        data: dts,
        name: dts.schoolName,
        description: dts.description,
        location: dts.schoolLocation,
        from: dts.from,
        to: dts.to,
        graduated: dts.graduated.toString(),
        current: dts.current,
      });
    } else if (id === "0") {
      this.setState({
        data: { _id: id },
        name: "",
        description: "",
        location: "",
        from: 0,
        to: 0,
        graduated: "-1",
        current: false,
      });
    }
  }

  onChangeCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === "current") {
      this.setState((prevState: any) => ({
        current: !prevState.current,
        to: 0,
        graduated: prevState.current ? "-1" : "1",
      }));
    }
  };

  onChangeEditForm = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.currentTarget.name === "graduated") {
      if (e.currentTarget.value === "1") {
        this.setState({
          current: true,
          to: 0,
          graduated: e.currentTarget.value,
        });
      } else {
        this.setState({
          [e.currentTarget.name]: e.currentTarget.value,
        });
      }
    } else {
      this.setState({
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  onSubmit = async (e: any): Promise<void> => {
    const { data, name, description, location, from, to, graduated, current } =
      this.state;

    e.preventDefault();
    this.props.setEducation(
      (data! as educationData)._id,
      name,
      description,
      location,
      from,
      to,
      graduated,
      current,
      (data! as educationData)._id === "0" ? true : false
    );

    this.props.resetWindow();
  };

  render() {
    const { name, description, location, from, to, graduated, current } =
      this.state;

    const { errors } = this.props;

    return (
      <EditEducationWrapper
        onChange={this.onChangeEditForm}
        onChangeCheck={this.onChangeCheck}
        onSubmit={this.onSubmit}
        cancel={this.props.resetWindow}
        name={name}
        description={description}
        locaction={location}
        from={from}
        to={to}
        graduated={graduated}
        current={current}
        errors={errors}
      />
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    education: state.content.educationById,
    errors: state.errors,
  };
};

export default withPermissions(
  connect(mapStateToProps, {
    setEducation,
  })(EditEducationController),
  "jmWeb:education:post"
);
