import React, { Component, RefObject } from "react";

interface LandingHamburgerMenuProps {
  onclick(): void;
  hamburgerRef: RefObject<HTMLDivElement>
}

export default class LandingHamburgerMenu extends Component<
  LandingHamburgerMenuProps,
  {}
> {
  render() {
    return (
      <div className="hamburgerButton" onClick={this.props.onclick}>
        <div className="barContainer">
            <div className="barElement"  ref={this.props.hamburgerRef}/>
          </div>
      </div>
    );
  }
}
