import { SET_ERROR_MESSAGE, SET_SUCCESS_MESSAGE } from "./types";

export const setErrorMsg = (msg: string) => {
  return {
    type: SET_ERROR_MESSAGE,
    payload: msg
  };
};

export const setSuccessMsg = (msg: string) => {
  return {
    type: SET_SUCCESS_MESSAGE,
    payload: msg
  };
};
