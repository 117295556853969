import React, {
  FunctionComponent,
  RefObject,
  useEffect,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getMessages } from "../../../actions/messagesActions";
import NotificationCounter from "../../common/NotificationCounter";
import WebsiteNav from "./WebsiteNav";

interface Props {
  firstMenuPointRef: RefObject<HTMLDivElement>;
  closeMenu?(): void;
  type: string;
}

export const Navigation: FunctionComponent<Props> = ({
  firstMenuPointRef,
  closeMenu,
  type,
}) => {
  const [activePoint, setActivePoint] = useState<HTMLDivElement | null>(
    firstMenuPointRef.current
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMessages());
  }, [dispatch]);

  useEffect(() => {
    const path: string = location.pathname.split("/")[2];

    if (path === "" || path === undefined) {
      navigate("/admin/start");
      setActivePoint(firstMenuPointRef.current);

      if (firstMenuPointRef.current) {
        firstMenuPointRef.current.classList.add(
          "sidebar__navigation--entry--active"
        );
      }
    } else {
      let element = document.querySelector<HTMLDivElement>(
        `.sidebar__navigation--entry.${path}`
      );

      if (element) {
        setActivePoint(element);
        element.classList.add("sidebar__navigation--entry--active");
      }
    }
  }, [navigate, firstMenuPointRef, location.pathname]);

  const changeActiveMenu = (e: any): void => {
    let toChange = e.target.parentElement.parentElement;

    if (!activePoint) return;

    activePoint.classList.remove("sidebar__navigation--entry--active");

    if (
      e.target.classList.contains("sidebar__navigation--notificationCounter")
    ) {
      toChange = toChange.parentElement;
    } else if (toChange.parentElement.tagName === "A") {
      toChange = toChange.parentElement.parentElement;
    }

    setActivePoint(toChange);
    try {
      toChange.classList.add("sidebar__navigation--entry--active");
    } catch (err) {}

    if (typeof closeMenu == "function") {
      closeMenu();
    }
  };

  return (
    <nav className="sidebar__navigation">
      <div className="sidebar__navigation--entry start" ref={firstMenuPointRef}>
        <Link
          to="/admin/start"
          onClick={changeActiveMenu}
          data-menutitle="start"
        >
          <i className="fa fa-home" />
          <span className="sidebar__navigation--title">Start</span>
        </Link>
      </div>

      <div className="sidebar__navigation--entry users">
        <Link
          to="/admin/users"
          onClick={changeActiveMenu}
          data-menutitle="users"
        >
          <i className="fa fa-user" />
          <span className="sidebar__navigation--title">Users</span>
        </Link>
      </div>

      <div className="sidebar__navigation--entry messages">
        <Link
          to="/admin/messages"
          onClick={changeActiveMenu}
          data-menutitle="messages"
        >
          <i className="fa fa-envelope">
            <NotificationCounter
              ownClassNames={["sidebar__navigation--notificationCounter"]}
            />
          </i>
          <span className="sidebar__navigation--title">Messages</span>
        </Link>
      </div>

      <div className="sidebar__navigation--entry website">
        <Link
          to="/admin/website"
          onClick={changeActiveMenu}
          data-menutitle="website"
          id="sidebar__dashboardWebsite"
        >
          <i className="fa fa-code-fork" />
          <span className="sidebar__navigation--title">Website</span>
        </Link>
        {type === "mobile" ? (
          <div className="sidebar__navigation--submenu" data-mainmenu="website">
            <WebsiteNav closeMenu={closeMenu} />
          </div>
        ) : null}
      </div>
      {type === "mobile" ? (
        <div className="sidebar__navigation--entry logout">
          <Link to="/logout" onClick={changeActiveMenu} data-menutitle="logout">
            <i className="fa fa-sign-out" />
            <span className="sidebar__navigation--title">Logout</span>
          </Link>
        </div>
      ) : null}
    </nav>
  );
};
