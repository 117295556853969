import React, { FunctionComponent } from 'react'

 const AccountWrapper: FunctionComponent = ({children}) =>  {
  return (
    <div>
        <h1>My Account</h1>
        Change Picture<br/>
        Change name<br/>
        Change email<br/>
        Change username<br/>
        Change password<br/>
        Logout
        {children}
    </div>
  )
}

export default AccountWrapper;