import React, { Component } from "react";
import classnames from "classnames";

interface PropUpWindowProps {
  onClickCloseIcon: (e: React.MouseEvent) => void;
  heading?: string;
  extraClasses?: Array<string>
}

class PopUpWindow extends Component<PropUpWindowProps, {}> {
  render() {
    return (
      <div className="popUpWindow">
        <i className="fa fa-close" onClick={this.props.onClickCloseIcon} />
        {this.props.heading && (
          <div className="popUpWindow__title">
            <h2 className="popUpWindow__title--heading">
              {this.props.heading}
            </h2>
            <div className="popUpWindow__title--seperator" />
          </div>
        )}
        <div className={`popUpWindow__content ${classnames(this.props.extraClasses)}`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PopUpWindow;
