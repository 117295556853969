export const animate = (
  duration: number,
  timing: (timeFraction: number) => number,
  draw: (progress: number) => void,
  onFinish?: () => void
) => {
  const start = performance.now();
  requestAnimationFrame(function animate(time) {
    let timefraction = (time - start) / duration;
    if (timefraction > 1) timefraction = 1;

    const progress = timing(timefraction);
    draw(progress);

    if (timefraction < 1) {
      requestAnimationFrame(animate);
    } else {
      if (onFinish) {
        onFinish();
      }
    }
  });
};
