import React, { Component, MouseEvent, ChangeEvent } from "react";
import { ExperieceDataInterface } from "../../../../../interfaces/Experience";
import { connect } from "react-redux";

import { setExperience } from "../../../../../actions/contentActions";
import EditExperienceWrapper from "./EditExperienceWrapper";
import { withPermissions } from "../../../../withPermissions";

interface Props {
  id: string;
  data: Array<ExperieceDataInterface>;
  resetWindow: () => void;
}

interface DispatchStateToProps {
  errors: any;
}

interface DispatchToProps {
  setExperience: (
    id: string,
    companyName: string,
    description: string,
    companyLocation: string,
    from: Date,
    to: Date,
    current: boolean,
    title: string,
    type: number,
    update: boolean
  ) => void;
}

type ReduxProps = DispatchStateToProps & DispatchToProps;

class EditExperienceController extends Component<Props & ReduxProps> {
  state = {
    saveEntry: false,
    data: undefined,
    name: "",
    title: "",
    description: "",
    location: "",
    from: new Date(),
    to: new Date(),
    current: false,
    type: 0,
  };

  componentDidMount() {
    const { id, data } = this.props;

    let filterDataForId: Array<ExperieceDataInterface> = data.filter(
      (el) => el._id === id
    );

    if (filterDataForId.length > 0) {
      let dts: ExperieceDataInterface = filterDataForId[0];
      this.setState({
        data: dts,
        name: dts.companyName,
        title: dts.title,
        description: dts.description,
        location: dts.companyLocation,
        from: dts.from,
        to: dts.to,
        current: dts.current || false,
        type: dts.type,
      });
    } else if (id === "0") {
      this.setState({
        data: { _id: id },
        name: "",
        description: "",
        title: "",
        location: "",
        from: new Date(),
        to: new Date(),
        current: false,
        type: 0,
      });
    }
  }

  onChangeEditForm = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    console.log("changefull");
    if (e.currentTarget.dataset.type === "date") {
      console.log("changedataset");
      try {
        let value = parseInt(e.currentTarget.value);
        if (typeof value === "number") {
          console.log("change");
          let dt: Date;
          let isFrom: boolean = false;
          switch (e.currentTarget.name) {
            case "from_month":
              dt = new Date(this.state.from);
              dt.setMonth(value - 1);
              isFrom = true;
              break;
            case "from_year":
              dt = new Date(this.state.from);
              dt.setFullYear(value);
              isFrom = true;
              break;
            case "to_month":
              dt = new Date(this.state.to);
              dt.setMonth(value - 1);
              break;
            case "to_year":
              dt = new Date(this.state.to);
              dt.setFullYear(value);
              break;
          }

          isFrom ? this.setState({ from: dt! }) : this.setState({ to: dt! });
        }
      } catch (error) {
        return;
      }
    } else if (e.currentTarget.getAttribute("type") === "checkbox") {
      console.log(e.currentTarget.value);
      this.setState({
        [e.currentTarget.name]:
          e.currentTarget.value === "false" ? true : false,
      });
    } else {
      this.setState({
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  onSubmit = (e: MouseEvent<HTMLFormElement>) => {
    const {
      data,
      name,
      title,
      description,
      location,
      from,
      to,
      current,
      type,
    } = this.state;

    e.preventDefault();
    this.props.setExperience(
      (data! as ExperieceDataInterface)._id,
      name,
      description,
      location,
      from,
      to,
      current,
      title,
      type,
      (data! as ExperieceDataInterface)._id === "0" ? false : true
    );

    this.props.resetWindow();
  };

  render() {
    const {
      data,
      name,
      title,
      description,
      location,
      from,
      to,
      current,
      type,
    } = this.state;
    const { errors } = this.props;

    const f = new Date(from);
    const t = new Date(to);

    return (
      <EditExperienceWrapper
        onChange={this.onChangeEditForm}
        onSubmit={this.onSubmit}
        cancel={this.props.resetWindow}
        data={data}
        name={name}
        title={title}
        description={description}
        location={location}
        from_month={f.getMonth() + 1}
        from_year={f.getFullYear()}
        to_month={t.getMonth() + 1}
        to_year={t.getFullYear()}
        type={type}
        current={current}
        errors={errors}
      />
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    errors: state.errors,
  };
};

export default withPermissions(
  connect(mapStateToProps, { setExperience })(EditExperienceController),
  "jmWeb:experience:post"
);
