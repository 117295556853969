import React, {
  Component,
  ReactNode,
  Children,
  isValidElement,
  MouseEvent,
} from "react";
import { mod } from "../../../utils/mod";
import { IndicatorV2 } from "./IndicatorV2";
import { CarousselCard } from "./CarousselCard";

import "./CarousselV2Style.scss";
import { animate } from "../../../utils/animate";

enum Direction {
  LEFT,
  RIGHT,
}

interface Props {
  children: ReactNode;
  infiniteScroll: boolean;
  indicator: boolean;
}

interface State {
  activeIndex: number;
  touchSlidingCard: {
    oldOverflowValue: string;
    oldTransitionValue: string;
    start: number;
    actual: number;
    dir: Direction;
    sliding: boolean;
  };
}

export class CarousselV2 extends Component<Props, State> {
  state = {
    activeIndex: 0,
    touchSlidingCard: {
      start: 0,
      actual: 0,
      dir: Direction.LEFT,
      oldOverflowValue: "",
      oldTransitionValue: "",
      sliding: false,
    },
  };

  private activeBulletRef = React.createRef<HTMLSpanElement>();
  private cardWrapperRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    //listener for arrowkey navigation
    document.addEventListener("keydown", this.onArrowKeyPressNavigation);

    //listener for touch input
    document.addEventListener("touchstart", this.startedTouch);
    document.addEventListener("touchend", this.endedTouch);
    document.addEventListener("touchcancel", this.canceledTouch);
    document.addEventListener("touchmove", this.movedTouch);
  }

  componentWillUnmount() {
    //remove all listeners for this component
    document.removeEventListener("keydown", this.onArrowKeyPressNavigation);

    document.removeEventListener("touchstart", this.startedTouch);
    document.removeEventListener("touchend", this.endedTouch);
    document.removeEventListener("touchcancel", this.canceledTouch);
    document.removeEventListener("touchmove", this.movedTouch);
  }

  componentDidUpdate(_: Props, prevState: State) {
    if (prevState.activeIndex !== this.activeIndex) {
      console.log("active index", this.activeIndex);
    }
  }

  startedTouch = (e: TouchEvent) => {
    this.setState((prevState: State) => ({
      touchSlidingCard: {
        ...prevState.touchSlidingCard,
        oldOverflowValue: document.body.style.overflow,
      },
    }));
    document.body.style.overflow = "hidden";

    this.cardWrapperRef.current!.style.transition = "none";

    this.setState((prevState: State) => ({
      touchSlidingCard: {
        ...prevState.touchSlidingCard,
        sliding: true,
        start: e.touches[0].clientX,
      },
    }));
  };

  endedTouch = (e: TouchEvent) => {
    const {
      touchSlidingCard: { oldOverflowValue, oldTransitionValue, sliding },
    } = this.state;
    document.body.style.overflow = oldOverflowValue;
    this.cardWrapperRef.current!.style.transition = oldTransitionValue;

    if (sliding) {
      this.cardWrapperRef.current!.style.transform = `translateX(-${
        (this.activeIndex + (this.props.infiniteScroll ? 1 : 0)) * 100
      }%)`;
    } else {
      this.setState((prevState: State) => ({
        touchSlidingCard: { ...prevState.touchSlidingCard, sliding: false },
      }));
    }
  };

  canceledTouch = (_: TouchEvent) => {
    const {
      touchSlidingCard: { oldOverflowValue, oldTransitionValue },
    } = this.state;
    document.body.style.overflow = oldOverflowValue;
    this.cardWrapperRef.current!.style.transition = oldTransitionValue;
  };

  movedTouch = (e: TouchEvent) => {
    //TODO animate bullet point onScroll too
    const {
      touchSlidingCard: { start, sliding },
    } = this.state;

    if (this.childrenCount > 0 && sliding) {
      const touch = e.touches[0].clientX;
      const dir: Direction = start < touch ? Direction.RIGHT : Direction.LEFT;

      let relDiff: number;
      let newMovValue: number;
      const objWidth = this.cardWrapperRef.current?.clientWidth;
      if (dir === Direction.LEFT) {
        const diff = Math.abs(touch - start);
        relDiff = diff / objWidth!;
        newMovValue = relDiff * 100;
      } else {
        const diff = Math.abs(start - touch);
        relDiff = diff / objWidth!;
        newMovValue = -(relDiff * 100);
      }

      let sliding = true;
      const slideThresh = 10;
      if (newMovValue + slideThresh >= 100 && dir === Direction.LEFT) {
        this.goRight();
        sliding = false;
      } else if (newMovValue - slideThresh <= -100 && dir === Direction.RIGHT) {
        this.goLeft();
        sliding = false;
      } else {
        this.cardWrapperRef.current!.style.transform = `translateX(${
          dir === Direction.LEFT ? "-" : "-"
        }${
          (this.activeIndex + (this.props.infiniteScroll ? 1 : 0)) * 100 +
          newMovValue
        }%)`;
      }

      this.setState((prevState: State) => ({
        touchSlidingCard: {
          ...prevState.touchSlidingCard,
          actual: e.touches[0].clientX,
          dir,
          sliding,
        },
      }));
    }
  };

  onArrowKeyPressNavigation = (e: KeyboardEvent) => {
    if (e.code === "ArrowLeft") {
      this.goLeft();
    } else if (e.code === "ArrowRight") {
      this.goRight();
    }
  };

  private get childrenCount(): number {
    return Children.count(this.props.children);
  }

  private get activeIndex(): number {
    return this.state.activeIndex;
  }

  private set activeIndex(index: number) {
    this.setState({ activeIndex: index });
  }

  private get lastChild(): ReactNode {
    if (this.childrenCount === 0) {
      return null;
    }

    return Children.toArray(this.props.children)[this.childrenCount - 1];
  }

  private get firstChild(): ReactNode {
    if (this.childrenCount === 0) {
      return null;
    }

    return Children.toArray(this.props.children)[0];
  }

  goLeft = () => {
    if (!this.props.infiniteScroll && this.activeIndex - 1 < 0) {
      return;
    }

    const index = mod(this.activeIndex - 1, this.childrenCount);

    this.onChangeActiveElement(index);
  };

  goRight = () => {
    if (
      !this.props.infiniteScroll &&
      this.activeIndex + 1 > this.childrenCount - 1
    ) {
      return;
    }

    const index = mod(this.activeIndex + 1, this.childrenCount);
    this.onChangeActiveElement(index);
  };

  onChangeActiveElement = (index: number) => {
    if (index >= 0 && index < this.childrenCount) {
      if (
        (index === this.childrenCount - 1 && this.activeIndex === 0) ||
        (index === 0 && this.activeIndex === this.childrenCount - 1)
      ) {
        const tr = this.cardWrapperRef.current!.style.transition;

        this.cardWrapperRef.current!.style.transition = "none";

        const dir: "l" | "r" =
          index === this.childrenCount - 1 && this.activeIndex === 0
            ? "l"
            : "r";

        animate(
          200,
          (t) => t,
          (p) => {
            console.log(p);

            if (p < 0) p = 0;

            if (p > 0.85) {
              this.activeIndex = index;
            } else {
              this.cardWrapperRef.current!.style.transform = `translateX(-${
                dir === "l" ? (1 - p) * 100 : p * 100
              }%)`;
            }
          },
          () => {
            setTimeout(() => {
              this.cardWrapperRef.current!.style.transition = tr;
            }, 20);
          }
        );

        //TODO use animate instead of transitions
      } else this.activeIndex = index;

      //animate bullet point
      let target: HTMLSpanElement;
      this.activeBulletRef
        .current!.parentElement!.querySelectorAll(
          ".jmCaroussel__indicatorBullet"
        )
        .forEach((el) => {
          const ele = el as HTMLSpanElement;
          const ai = ele.dataset.index;
          if (ai && parseInt(ai) === index) {
            target = ele;
          }
        });

      const calcOffset =
        target!.offsetLeft - this.activeBulletRef.current!.offsetLeft;
      const initVal = this.activeBulletRef.current!.offsetLeft;

      animate(
        200,
        (t) => t,
        (progress) => {
          if (this.activeBulletRef.current) {
            const newValue = calcOffset * progress;
            this.activeBulletRef.current.style.left = `${initVal + newValue}px`;
          }
        }
      );
    }
  };

  onClickCard = (e: MouseEvent<HTMLElement>) => {
    const index = e.currentTarget.dataset.index;
    if (!index) return;

    this.onChangeActiveElement(parseInt(index));
  };

  render() {
    const { children, infiniteScroll, indicator } = this.props;
    const { activeIndex } = this.state;

    return (
      <div className="jmCaroussel">
        {indicator && (
          <IndicatorV2
            length={this.childrenCount}
            activeIndicatorRef={this.activeBulletRef}
            changeActiveElement={this.onChangeActiveElement}
          />
        )}
        <div
          className="jmCaroussel__cards"
          ref={this.cardWrapperRef}
          style={{
            transform: `translateX(-${
              activeIndex + (infiniteScroll ? 1 : 0)
            }00%)`,
          }}
        >
          {infiniteScroll && (
            <CarousselCard
              onClick={this.onClickCard}
              index={this.childrenCount - 1}
            >
              {this.lastChild}
            </CarousselCard>
          )}
          {Children.map(children, (c, i) => {
            if (i === activeIndex) {
              if (isValidElement(c)) {
                return (
                  <CarousselCard active={true} index={i}>
                    {c}
                  </CarousselCard>
                );
              }
            } else
              return (
                <CarousselCard onClick={this.onClickCard} index={i}>
                  {c}
                </CarousselCard>
              );
          })}
          {infiniteScroll && (
            <CarousselCard onClick={this.onClickCard} index={0}>
              {this.firstChild}
            </CarousselCard>
          )}
        </div>
      </div>
    );
  }
}
