import React, { Component, MouseEvent } from "react";
import { connect } from "react-redux";

import ProjectsWrapper from "./ProjectsWrapper";
import {
  getProjects,
  setLoading,
  delProject,
} from "../../../../../actions/contentActions";
import { ProjectsDataInterface } from "../../../../../interfaces/Projects";
import isEmpty from "../../../../../functions/isEmpty";
import Modal from "../../../../common/Modal";
import EditProjectController from "./EditProjectController";
import { withPermissions } from "../../../../withPermissions";

interface StateToProps {
  projects: Array<ProjectsDataInterface>;
  isLoading: boolean;
}

interface DispatchToProps {
  getProjects: () => void;
  setLoading: (state: boolean) => void;
  delProject: (id: string) => void;
}

type ReduxProps = StateToProps & DispatchToProps;

class ProjectsController extends Component<ReduxProps> {
  state = {
    showModal: false,
    data: {},
    showAddMenu: false,
    showEditMenu: false,
    editId: "",
    delId: "",
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.props.getProjects();
  }

  addOne = () => {
    this.setState({
      showAddMenu: true,
    });
  };

  editOne = (e: MouseEvent<HTMLButtonElement>) => {
    this.setState({
      showEditMenu: true,
      editId: e.currentTarget.dataset.id,
    });
  };

  deleteOne = (e: MouseEvent<HTMLButtonElement>) => {
    this.setState({
      showModal: true,
      delId: e.currentTarget.dataset.id,
    });
  };

  abortDeleteOne = () => {
    this.setState({
      showModal: false,
      delId: "",
    });
  };

  confirmDeleteOne = () => {
    this.props.delProject(this.state.delId);
    this.abortDeleteOne();
  };

  cancelEdit = () => {
    this.props.getProjects();
    this.setState({
      showEditMenu: false,
      showAddMenu: false,
      editId: "",
    });
  };

  render() {
    const { projects, isLoading } = this.props;
    const { showModal, showAddMenu, showEditMenu, editId } = this.state;

    return (
      <ProjectsWrapper addOne={this.addOne}>
        {showModal && (
          <Modal
            text="Do you really want to delete this entry?"
            onAbort={this.abortDeleteOne}
            onContinue={this.confirmDeleteOne}
          />
        )}

        {showAddMenu && (
          <EditProjectController id="0" resetWindow={this.cancelEdit} />
        )}

        {showEditMenu && (
          <EditProjectController
            id={editId}
            data={projects}
            resetWindow={this.cancelEdit}
          />
        )}

        {!isLoading && !isEmpty(projects)
          ? projects.map((project, i) => (
              <div className="adminProjects__entry" key={i}>
                <h3>{project.name}</h3>
                <div className="adminProjects__entry--dates">
                  <p>{project.from}</p>
                  &nbsp;
                  <span className="m-dash-edu">&mdash;</span>
                  &nbsp;
                  <p>{project.current ? "now" : project.to}</p>
                </div>
                <div className="adminProjects__entry--buttons">
                  <button
                    onClick={this.editOne}
                    data-id={project._id}
                    className="btn btn-midBlue"
                  >
                    Edit
                  </button>
                  <button
                    onClick={this.deleteOne}
                    data-id={project._id}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))
          : null}
      </ProjectsWrapper>
    );
  }
}

const mapStateToProps = (state: any) => ({
  projects: state.content.projects,
  isLoading: state.content.isLoading,
});

export default withPermissions(
  connect(mapStateToProps, { getProjects, setLoading, delProject })(
    ProjectsController
  ),
  "jmWeb:project:get"
);
