import React, { FunctionComponent, ReactNode } from "react";

interface Props {
  children: ReactNode;
  addOne: () => void;
}

const EducationWrapper: FunctionComponent<Props> = ({ children, addOne }) => {
  return (
    <div className="adminEducation">
      {children}
      <p className="btn-round btn-midBlue" onClick={addOne}>
        <span>+</span>
      </p>
    </div>
  );
};

export default EducationWrapper;
