import React, { Component } from "react";
import AccountWrapper from "./AccountWrapper";

export default class AccountContoller extends Component {
  render() {
    return (
      <AccountWrapper>
        <h2>Controller</h2>
      </AccountWrapper>
    );
  }
}
