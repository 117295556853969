import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

interface LogoCardProps {
  iconClasses: Array<String>;
  text: String;
  link?: String;
  linkName?: String;
  onClick?: (e: any) => void;
}

class LogoCard extends Component<LogoCardProps, {}> {
  render() {
    return (
      <div className="card card--logoCard">
        <i className={classnames(...this.props.iconClasses)} />
        <p className="logoCard__text">{this.props.text}</p>
        {this.props.link && (
          <Link to={`${this.props.link}`}>
            {this.props.linkName
              ? this.props.linkName + " →"
              : "This is a Link →"}
          </Link>
        )}
        {this.props.linkName && this.props.onClick && (
          <p onClick={this.props.onClick} className="link">{this.props.linkName + " →"}</p>
        )}
      </div>
    );
  }
}

export default LogoCard;
