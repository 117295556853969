import axios from "axios";
import { GET_ALLMESSAGES, SET_ERROR } from "./types";
import { constants } from "../utils/constants";

const apiKey = "HR5MYPtgzMYY6oEtMkQcse7aRBVWrlsPFMFemUx2";

export const getMessages = () => (dispatch: any) => {
  axios
    .get(`${constants.API_URL}/api/messages?apiKey=${apiKey}`)
    .then((res: any) =>
      dispatch({
        type: GET_ALLMESSAGES,
        payload: res.data,
      })
    )
    .catch(
      (err) => console.log(err)
      /* dispatch({
    type: SET_ERROR,
    payload: err.response.data
    }) */
    );
};

export const setMessage = (data: any) => (dispatch: any) => {
  axios
    .post(`${constants.API_URL}/api/messages?apiKey=${apiKey}`, {
      name: data.name,
      email: data.email,
      text: data.text,
    })
    .then((_) =>
      dispatch({
        type: SET_ERROR,
        payload: { nomessageerror: "Success" },
      })
    )
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: SET_ERROR,
          payload: { messageerror: err.response.data },
        });
      } else {
        dispatch({
          type: SET_ERROR,
          payload: { messageerror: "Failed to send message" },
        });
      }
    });
};

export const markAsReadMessage =
  (id: any, isRead: boolean) => (dispatch: any) => {
    axios
      .post(`${constants.API_URL}/api/messages?apiKey=${apiKey}`, {
        id,
        isRead,
      })
      .then(() => dispatch(getMessages()))
      .catch(
        (err) => console.log(err)
        /* dispatch({
    type: SET_ERROR,
    payload: err.response.data
    }) */
      );
  };

export const delMessage = (id: any) => (dispatch: any) => {
  axios
    .delete(`${constants.API_URL}/api/messages/${id}`)
    .then(() => dispatch(getMessages()))
    .catch(
      (err) => console.log(err)
      /* dispatch({
    type: SET_ERROR,
    payload: err.response.data
    }) */
    );
};
