import React, { Component, createRef, ReactChild } from "react";
import { Navigation } from "../Navigation";
import { connect } from "react-redux";
import { AuthInterface } from "../../../../interfaces/Auth";
import { Link } from "react-router-dom";

interface Props {
  children: ReactChild;
}

interface StateToProps {
  auth: AuthInterface;
}
class MLayout extends Component<Props & StateToProps> {
  private firstMenuPointRef = createRef<HTMLDivElement>();

  state = {
    showSidebar: false,
    animateMenu: false,
  };

  componentDidMount() {
    document.addEventListener("touchmove", this.moveMenuOut);
    document.addEventListener("touchstart", this.startMenuOut);
    document.addEventListener("touchend", this.endMenuOut);
  }

  componentWillUnmount() {
    document.removeEventListener("touchmove", this.moveMenuOut);
    document.removeEventListener("touchstart", this.startMenuOut);
    document.removeEventListener("touchend", this.endMenuOut);
  }

  moveMenuOut = (e: TouchEvent): void => {
    const { animateMenu } = this.state;

    let touch = e.changedTouches[0];
    let el = document.getElementById("mobileDashboard__sidebar")!;
    let elPrev: HTMLDivElement = el.previousElementSibling as HTMLDivElement;

    if (animateMenu) {
      el.classList.add("u-notransition");
      elPrev.style.visibility = "visible";
      el.style.boxShadow = "0 0 1rem  rgba( 0, 0, 0,  0.8)";
      elPrev.style.opacity = `${Math.abs(
        touch.pageX / this.calulateWidthInPercent(70)
      )}`;
      el.style.transform = `translateX(-${
        100 - (touch.pageX / this.calulateWidthInPercent(70)) * 100
      }%)`;
    }

    setTimeout(() => {
      if (el.classList.contains("u-notransition")) {
        el.classList.remove("u-notransition");
      }
    }, 10);
  };

  startMenuOut = (e: TouchEvent): void => {
    const { showSidebar } = this.state;

    if (
      (e.targetTouches[0].pageX <= 25 && !showSidebar) ||
      (e.targetTouches[0].pageX >= this.calulateWidthInPercent(70) - 50 &&
        showSidebar)
    ) {
      this.setState({ animateMenu: true });
    }
  };

  endMenuOut = (e: TouchEvent): void => {
    let el = document.getElementById("mobileDashboard__sidebar")!;
    let translateX: number = parseInt(
      el.style.transform!.replace(/[^\d.]/g, "")
    );

    if (el.classList.contains("u-notransition")) {
      el.classList.remove("u-notransition");
    }

    if (translateX < 45) {
      this.menuShowStyle(el);
      this.setState({ showSidebar: true });
    } else {
      this.menuHideStyle(el);
      this.setState({ showSidebar: false });
    }

    this.setState({ animateMenu: false });
  };

  calulateWidthInPercent = (winWidth: number) => {
    return window.innerWidth * (winWidth / 100);
  };

  onOpenSidebar = (): void => {
    this.setState({ showSidebar: true });
    let el = document.getElementById("mobileDashboard__sidebar")!;

    if (el.classList.contains("u-notransition")) {
      el.classList.remove("u-notransition");
    }

    this.menuShowStyle(el);
  };

  onCloseSidebar = (): void => {
    let el = document.getElementById("mobileDashboard__sidebar")!;
    this.setState({ showSidebar: false });

    if (el.classList.contains("u-notransition")) {
      el.classList.remove("u-notransition");
    }
    this.menuHideStyle(el);
  };

  menuHideStyle = (el: HTMLElement): void => {
    let elPrev: HTMLDivElement = el.previousElementSibling as HTMLDivElement;
    elPrev.style.visibility = "hidden";
    elPrev.style.opacity = `0`;
    el.style.boxShadow = "none";
    el.style.transform = `translateX(-100%)`;
  };

  menuShowStyle = (el: HTMLElement): void => {
    let elPrev: HTMLDivElement = el.previousElementSibling as HTMLDivElement;
    el.style.transform = `translateX(0)`;
    elPrev.style.visibility = "visible";
    elPrev.style.opacity = `1`;
    el.style.boxShadow = "0 0 1rem rgba( 0, 0, 0,  0.8)";
  };

  render() {
    const {
      auth: {
        userInfo: { firstName, lastName, avatar },
      },
    } = this.props;

    return (
      <div className="mobileDashboard">
        <header className="mobileDashboard__header">
          <i
            className="fa fa-bars mobileDashboard__openIcon"
            onClick={this.onOpenSidebar}
          />
        </header>
        <div className="sidebarBG" id="mobileSidebarWrapper" />
        <aside className="sidebar" id="mobileDashboard__sidebar">
          <i
            className="fa fa-times sidebar__closeIcon"
            onClick={this.onCloseSidebar}
          />
          <div className="sidebar__topPart">
            <img
              src={avatar}
              alt={firstName + " " + lastName}
              className="sidebar__topPart--profileImage"
            />
            <h2 className="sidebar__topPart--userName">
              <Link to="/admin/account">{firstName + " " + lastName}</Link>
            </h2>
            <div className="sidebar__seperator" />
          </div>
          <Navigation
            firstMenuPointRef={this.firstMenuPointRef}
            type="mobile"
            closeMenu={this.onCloseSidebar}
          />
        </aside>
        <div className="mobileDashboard__content">{this.props.children}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.user,
});

export default connect(mapStateToProps, {})(MLayout);
