import { combineReducers } from "redux";
import contentReducer from "./contentReducer";
import usersReducer from "./usersReducer";
import errorReducer from "./errorReducer";
import messageReducer from "./messageReducer";
import feedbackMessagesReducer from "./feedbackMessagesReducer";

export default combineReducers({
  content: contentReducer,
  user: usersReducer,
  errors: errorReducer,
  messages: messageReducer,
  feedbackMessages: feedbackMessagesReducer
});
