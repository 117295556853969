import React, { Component } from "react";
import { connect } from "react-redux";

import { getUsers } from "../../../../actions/usersActions";
import { UserInfoInterface } from "../../../../interfaces/Auth";
import { withPermissions } from "../../../withPermissions";

interface PropsFromState {
  allUsers: UserInfoInterface[];
}

interface PropsFromDispatch {
  getUsers: () => void;
}

type ReduxProps = PropsFromState & PropsFromDispatch;

class Users extends Component<ReduxProps, {}> {
  componentDidMount() {
    this.props.getUsers();
  }

  render() {
    const { allUsers } = this.props;

    return (
      <div>
        <h1>Users: (total: {allUsers.length > 0 && allUsers.length})</h1>
        {allUsers.length &&
          allUsers.map((el: UserInfoInterface) => (
            <div key={el._id}>
              {el._id}
              {el.firstName + " " + el.lastName}
              {el.email}
              {el.username}
            </div>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  allUsers: state.user.allUsers,
});

export default withPermissions(
  connect(mapStateToProps, { getUsers })(Users),
  "jmWeb:user:get"
);
