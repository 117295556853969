import React, { FunctionComponent, MouseEvent } from "react";

interface Props {
  text: string;
  onContinue: (e: MouseEvent<HTMLElement>) => void;
  onAbort: () => void;
}

const Modal: FunctionComponent<Props> = ({ text, onContinue, onAbort }) => {
  return (
    <div className="u-modal-window">
      <div className="u-modal-window__content">
        {text}
        <div className="u-modal-window__buttons">
          <button onClick={onContinue} className="btn btn-success">
            Continue
          </button>
          <button onClick={onAbort} className="btn btn-danger">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
