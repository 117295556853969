import React, { Component, ChangeEvent, MouseEvent } from "react";
import { connect } from "react-redux";

import { ProjectsDataInterface } from "../../../../../interfaces/Projects";
import {
  getProjectById,
  setProjects,
} from "../../../../../actions/contentActions";
import EditProjectWrapper from "./EditProjectWrapper";
import { withPermissions } from "../../../../withPermissions";

interface Props {
  id: string;
  data?: Array<ProjectsDataInterface>;
  resetWindow: () => void;
}

interface DispatchToProps {
  getProjectById: (id: string) => void;
  setProjects: (
    name: string,
    shortDescription: string,
    description: string,
    showcaseImage: string,
    images: string,
    from: number,
    to: number,
    current: boolean,
    urls: string,
    skills: string,
    id: string,
    update: boolean
  ) => void;
}

interface StateToProps {
  projectById: ProjectsDataInterface;
  isLoading: boolean;
}

type ReduxProps = DispatchToProps & StateToProps;

class EditProjectController extends Component<Props & ReduxProps> {
  state = {
    name: "",
    shortDescription: "",
    description: "",
    showcaseImage: "",
    images: [],
    from: new Date().getFullYear(),
    to: new Date().getFullYear(),
    current: false,
    urls: [],
    skills: [],
    id: "",
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { id, data } = this.props;

    if (data !== undefined) {
      const dts: ProjectsDataInterface = data.filter(
        (item) => item._id === id
      )[0];
      this.setState({
        name: dts.name,
        shortDescription: dts.shortDescription,
        description: dts.description,
        showcaseImage: dts.showcaseImage,
        images: dts.images,
        from: dts.from,
        to: dts.to,
        current: dts.current,
        urls: dts.urls,
        skills: dts.skills,
        id: dts._id,
      });
    } else if (id === "0") {
      this.setState({
        name: "",
        shortDescription: "",
        description: "",
        showcaseImage: "",
        images: [],
        from: 0,
        to: 0,
        current: false,
        urls: [],
        skills: [],
        id: "0",
      });
    }
  };

  onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeArrayItem = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { type, pos } = e.target.dataset;
    const posNum =
      e.target.dataset.pos !== undefined ? parseInt(pos as any) : 0;

    const { value } = e.target;

    let arrayCopy: Array<string>;

    switch (type) {
      case "image":
        arrayCopy = this.state.images;
        arrayCopy[posNum] = value;
        this.setState({
          images: arrayCopy,
        });
        break;
      case "skill":
        arrayCopy = this.state.skills;
        arrayCopy[posNum] = value;
        this.setState({
          skills: arrayCopy,
        });
        break;
      case "url":
        arrayCopy = this.state.urls;
        arrayCopy[posNum] = value;
        this.setState({
          urls: arrayCopy,
        });
        break;
    }
  };

  onChangeCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === "current") {
      this.setState((prevState: any) => ({
        current: !prevState.current,
        to: prevState.current ? new Date().getFullYear() : 0,
      }));
    }
  };

  onAddImage = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState((prevState: any) => ({
      images: prevState.images.concat(""),
    }));
  };

  onAddSkill = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState((prevState: any) => ({
      skills: prevState.skills.concat(""),
    }));
  };

  onAddUrl = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState((prevState: any) => ({
      urls: prevState.urls.concat(""),
    }));
  };

  onRemImage = (e: MouseEvent<HTMLElement>) => {
    const array = this.state.images;

    array.splice((e as any).target.dataset.pos, 1);

    this.setState({
      images: array,
    });
  };

  onRemSkill = (e: MouseEvent<HTMLElement>) => {
    const array = this.state.skills;

    array.splice((e as any).target.dataset.pos, 1);

    this.setState({
      skills: array,
    });
  };

  onRemUrl = (e: MouseEvent<HTMLElement>) => {
    const array = this.state.urls;

    array.splice((e as any).target.dataset.pos, 1);

    this.setState({
      urls: array,
    });
  };

  onSubmit = (e: any) => {
    const {
      name,
      shortDescription,
      description,
      showcaseImage,
      images,
      from,
      to,
      current,
      urls,
      skills,
      id,
    } = this.state;

    e.preventDefault();

    this.props.setProjects(
      name,
      shortDescription,
      description,
      showcaseImage,
      images.join(";"),
      from,
      to,
      current,
      urls.join(";"),
      skills.join(","),
      id,
      id === "0" ? false : true
    );
  };

  render() {
    const {
      name,
      shortDescription,
      description,
      showcaseImage,
      images,
      from,
      to,
      current,
      urls,
      skills,
    } = this.state;

    const { resetWindow } = this.props;

    return (
      <EditProjectWrapper
        onChange={this.onChange}
        onChangeArrayItem={this.onChangeArrayItem}
        onChangeCheck={this.onChangeCheck}
        onSubmit={this.onSubmit}
        onAddImage={this.onAddImage}
        onRemImage={this.onRemImage}
        onAddUrl={this.onAddUrl}
        onRemUrl={this.onRemUrl}
        onAddSkill={this.onAddSkill}
        onRemSkill={this.onRemSkill}
        cancel={resetWindow}
        name={name}
        shortDescription={shortDescription}
        description={description}
        showcaseImage={showcaseImage}
        images={images}
        from={from}
        to={to}
        current={current}
        urls={urls}
        skills={skills}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  projectById: state.content.projectById,
  isLoading: state.content.isLoading,
});

export default withPermissions(
  connect(mapStateToProps, { getProjectById, setProjects })(
    EditProjectController
  ),
  "jmWeb:project:post"
);
