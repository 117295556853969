import React from "react";

type Props = {
  onclick(e: any): void;
};

export const Instagram: React.SFC<Props> = ({ onclick }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      data-service="instagram"
      viewBox="0 0 551.034 551.034"
      className="instagramSVGIcon"
      onClick={onclick}
    >
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="275.517"
          y1="4.57"
          x2="275.517"
          y2="549.72"
          gradientTransform="matrix(1 0 0 -1 0 554)"
        >
          <stop offset="0" style={{ stopColor: "#E09B3D" }} />
          <stop offset="0.3" style={{ stopColor: "#C74C4D" }} />
          <stop offset="0.6" style={{ stopColor: "#C21975" }} />
          <stop offset="1" style={{ stopColor: "#7024C4" }} />
        </linearGradient>
        <path
          style={{ fill: "url(#SVGID_1_)" }}
          d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722
            c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156
            C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156
            c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722
            c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"
        />

        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="275.517"
          y1="4.57"
          x2="275.517"
          y2="549.72"
          gradientTransform="matrix(1 0 0 -1 0 554)"
        >
          <stop offset="0" style={{ stopColor: "#E09B3D" }} />
          <stop offset="0.3" style={{ stopColor: "#C74C4D" }} />
          <stop offset="0.6" style={{ stopColor: "#C21975" }} />
          <stop offset="1" style={{ stopColor: "#7024C4" }} />
        </linearGradient>
        <path
          style={{ fill: "url(#SVGID_2_)" }}
          d="M275.517,133C196.933,133,133,196.933,133,275.516s63.933,142.517,142.517,142.517
            S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6c-48.095,0-87.083-38.988-87.083-87.083
            s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083C362.6,323.611,323.611,362.6,275.517,362.6z"
        />

        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1="418.31"
          y1="4.57"
          x2="418.31"
          y2="549.72"
          gradientTransform="matrix(1 0 0 -1 0 554)"
        >
          <stop offset="0" style={{ stopColor: "#E09B3D" }} />
          <stop offset="0.3" style={{ stopColor: "#C74C4D" }} />
          <stop offset="0.6" style={{ stopColor: "#C21975" }} />
          <stop offset="1" style={{ stopColor: "#7024C4" }} />
        </linearGradient>
        <circle
          style={{ fill: "url(#SVGID_3_)" }}
          cx="418.31"
          cy="134.07"
          r="34.15"
        />
      </g>
    </svg>
  );
};

export const Twitter: React.SFC<Props> = ({ onclick }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      data-service="twitter"
      viewBox="0 0 410.155 410.155"
      className="twitterSVGIcon"
      onClick={onclick}
    >
      <path
        style={{ fill: "#76A9EA" }}
        d="M403.632,74.18c-9.113,4.041-18.573,7.229-28.28,9.537c10.696-10.164,18.738-22.877,23.275-37.067
      l0,0c1.295-4.051-3.105-7.554-6.763-5.385l0,0c-13.504,8.01-28.05,14.019-43.235,17.862c-0.881,0.223-1.79,0.336-2.702,0.336
      c-2.766,0-5.455-1.027-7.57-2.891c-16.156-14.239-36.935-22.081-58.508-22.081c-9.335,0-18.76,1.455-28.014,4.325
      c-28.672,8.893-50.795,32.544-57.736,61.724c-2.604,10.945-3.309,21.9-2.097,32.56c0.139,1.225-0.44,2.08-0.797,2.481
      c-0.627,0.703-1.516,1.106-2.439,1.106c-0.103,0-0.209-0.005-0.314-0.015c-62.762-5.831-119.358-36.068-159.363-85.14l0,0
      c-2.04-2.503-5.952-2.196-7.578,0.593l0,0C13.677,65.565,9.537,80.937,9.537,96.579c0,23.972,9.631,46.563,26.36,63.032
      c-7.035-1.668-13.844-4.295-20.169-7.808l0,0c-3.06-1.7-6.825,0.485-6.868,3.985l0,0c-0.438,35.612,20.412,67.3,51.646,81.569
      c-0.629,0.015-1.258,0.022-1.888,0.022c-4.951,0-9.964-0.478-14.898-1.421l0,0c-3.446-0.658-6.341,2.611-5.271,5.952l0,0
      c10.138,31.651,37.39,54.981,70.002,60.278c-27.066,18.169-58.585,27.753-91.39,27.753l-10.227-0.006
      c-3.151,0-5.816,2.054-6.619,5.106c-0.791,3.006,0.666,6.177,3.353,7.74c36.966,21.513,79.131,32.883,121.955,32.883
      c37.485,0,72.549-7.439,104.219-22.109c29.033-13.449,54.689-32.674,76.255-57.141c20.09-22.792,35.8-49.103,46.692-78.201
      c10.383-27.737,15.871-57.333,15.871-85.589v-1.346c-0.001-4.537,2.051-8.806,5.631-11.712c13.585-11.03,25.415-24.014,35.16-38.591
      l0,0C411.924,77.126,407.866,72.302,403.632,74.18L403.632,74.18z"
      />
    </svg>
  );
};

/*export const Spotify: React.SFC<Props> = ({ onclick }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      data-service="spotify"
      viewBox="0 0 427.652 427.652"
      className="spotifySVGIcon"
      onClick={onclick}
    >
      <path
        style={{ fill: "#00D95F" }}
        d="M213.826,0C95.733,0,0,95.733,0,213.826s95.733,213.826,213.826,213.826
	s213.826-95.733,213.826-213.826S331.919,0,213.826,0z M306.886,310.32c-2.719,4.652-7.612,7.246-12.638,7.247
	c-2.506,0-5.044-0.645-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138c-33.763,1.027-58.523,7.692-58.769,7.76
	c-7.783,2.126-15.826-2.454-17.961-10.236c-2.134-7.781,2.43-15.819,10.209-17.962c1.116-0.307,27.76-7.544,64.811-8.766
	c21.824-0.72,42.834,0.801,62.438,4.52c24.83,4.71,47.48,12.978,67.322,24.574C308.612,294.393,310.96,303.349,306.886,310.32z
	 M334.07,253.861c-3.22,5.511-9.016,8.583-14.97,8.584c-2.968,0-5.975-0.763-8.723-2.369c-45.514-26.6-98.097-30.873-134.2-29.776
	c-39.994,1.217-69.323,9.112-69.614,9.192c-9.217,2.515-18.746-2.906-21.275-12.124c-2.528-9.218,2.879-18.738,12.093-21.277
	c1.322-0.364,32.882-8.937,76.77-10.384c25.853-0.852,50.739,0.949,73.96,5.354c29.412,5.58,56.241,15.373,79.744,29.108
	C336.115,234.995,338.897,245.603,334.07,253.861z M350.781,202.526c-3.641,0-7.329-0.936-10.7-2.906
	c-108.207-63.238-248.572-25.643-249.977-25.255c-11.313,3.117-23.008-3.527-26.124-14.839
	c-3.117-11.312,3.527-23.008,14.839-26.124c1.621-0.447,40.333-10.962,94.166-12.737c31.713-1.044,62.237,1.164,90.72,6.567
	c36.077,6.844,68.987,18.856,97.815,35.704c10.13,5.92,13.543,18.931,7.623,29.061C365.193,198.757,358.084,202.526,350.781,202.526
	z"
      />
    </svg>
  );
};*/

export const AppleMusic: React.FunctionComponent<Props> = ({ onclick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 120 120"
      data-service="applemusic"
      className="appleMusicSVGIcon"
      onClick={onclick}
    >
      <defs>
        <linearGradient
          id="a"
          x1="-392"
          x2="-272"
          y1="-764"
          y2="-764"
          gradientTransform="rotate(-90 276 -548)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fd355a" />
          <stop offset="1" stopColor="#fd5163" />
        </linearGradient>
        <radialGradient
          id="b"
          cx="-400.171"
          cy="-984.067"
          r="1.587"
          gradientTransform="matrix(0 31.8923 31.8923 0 31421.78 12852.762)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#7470f9" />
          <stop offset="1" stopColor="#7a70fe" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="c"
          cx="-489.182"
          cy="-1019.984"
          r="1.281"
          gradientTransform="scale(-31.8923 31.8923) rotate(-74.462 426.955 -829.031)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#4ca3f8" />
          <stop offset=".362" stopColor="#4ca4f7" />
          <stop offset="1" stopColor="#4aa2f9" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="d"
          x1="-418.42"
          x2="-418.034"
          y1="-1040.402"
          y2="-1041.278"
          gradientTransform="matrix(31.8923 0 0 -39.8681 13392.678 -41444.467)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ff6380" />
          <stop offset="1" stopColor="#f65e79" stopOpacity="0" />
        </linearGradient>
      </defs>
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M94,120H26A25.9482,25.9482,0,0,1,0,94V26A25.9482,25.9482,0,0,1,26,0H94a25.9482,25.9482,0,0,1,26,26V94A25.9482,25.9482,0,0,1,94,120Z"
      />
      <g data-name='Home-Screen-11"'>
        <path
          fill="#bb58bb"
          fillRule="evenodd"
          d="M88,72.5V76a37.93334,37.93334,0,0,1-.6,6.7,8.5338,8.5338,0,0,1-3.6,5.6h-.1a12.35558,12.35558,0,0,1-6.9,2.5l-1.9.2A9.53981,9.53981,0,0,1,67,87.6a8.779,8.779,0,0,1-1.4-8.8v-.1a9.24834,9.24834,0,0,1,6.5-5.8l7.5-2a4.29583,4.29583,0,0,0,3.3-4.2V61.6h0V40.1a1.61246,1.61246,0,0,0-.6-1.3,1.73546,1.73546,0,0,0-1.4-.3L48.9,45a2.37562,2.37562,0,0,0-2,2.4V81.6h0v2.5a37.93323,37.93323,0,0,1-.6,6.7,8.53375,8.53375,0,0,1-3.6,5.6h-.1a12.35561,12.35561,0,0,1-6.9,2.5l-1.8.1A9.53981,9.53981,0,0,1,26,95.6a8.779,8.779,0,0,1-1.4-8.8v-.1a9.24834,9.24834,0,0,1,6.5-5.8l7.5-2a4.29583,4.29583,0,0,0,3.3-4.2V69.6h0V29.9a3.71845,3.71845,0,0,1,3-3.6L85,19a3.14378,3.14378,0,0,1,2.1.5,2.46275,2.46275,0,0,1,.9,1.9V72.5Z"
        />
        <path
          fill="url(#b)"
          fillRule="evenodd"
          d="M88,72.5V76a37.93334,37.93334,0,0,1-.6,6.7,8.5338,8.5338,0,0,1-3.6,5.6h-.1a12.35558,12.35558,0,0,1-6.9,2.5l-1.9.2A9.53981,9.53981,0,0,1,67,87.6a8.779,8.779,0,0,1-1.4-8.8v-.1a9.24834,9.24834,0,0,1,6.5-5.8l7.5-2a4.29583,4.29583,0,0,0,3.3-4.2V61.6h0V40.1a1.61246,1.61246,0,0,0-.6-1.3,1.73546,1.73546,0,0,0-1.4-.3L48.9,45a2.37562,2.37562,0,0,0-2,2.4V81.6h0v2.5a37.93323,37.93323,0,0,1-.6,6.7,8.53375,8.53375,0,0,1-3.6,5.6h-.1a12.35561,12.35561,0,0,1-6.9,2.5l-1.8.1A9.53981,9.53981,0,0,1,26,95.6a8.779,8.779,0,0,1-1.4-8.8v-.1a9.24834,9.24834,0,0,1,6.5-5.8l7.5-2a4.29583,4.29583,0,0,0,3.3-4.2V69.6h0V29.9a3.71845,3.71845,0,0,1,3-3.6L85,19a3.14378,3.14378,0,0,1,2.1.5,2.46275,2.46275,0,0,1,.9,1.9V72.5Z"
          data-name="path-6"
        />
        <path
          fill="url(#c)"
          fillRule="evenodd"
          d="M88,72.5V76a37.93334,37.93334,0,0,1-.6,6.7,8.5338,8.5338,0,0,1-3.6,5.6h-.1a12.35558,12.35558,0,0,1-6.9,2.5l-1.9.2A9.53981,9.53981,0,0,1,67,87.6a8.779,8.779,0,0,1-1.4-8.8v-.1a9.24834,9.24834,0,0,1,6.5-5.8l7.5-2a4.29583,4.29583,0,0,0,3.3-4.2V61.6h0V40.1a1.61246,1.61246,0,0,0-.6-1.3,1.73546,1.73546,0,0,0-1.4-.3L48.9,45a2.37562,2.37562,0,0,0-2,2.4V81.6h0v2.5a37.93323,37.93323,0,0,1-.6,6.7,8.53375,8.53375,0,0,1-3.6,5.6h-.1a12.35561,12.35561,0,0,1-6.9,2.5l-1.8.1A9.53981,9.53981,0,0,1,26,95.6a8.779,8.779,0,0,1-1.4-8.8v-.1a9.24834,9.24834,0,0,1,6.5-5.8l7.5-2a4.29583,4.29583,0,0,0,3.3-4.2V69.6h0V29.9a3.71845,3.71845,0,0,1,3-3.6L85,19a3.14378,3.14378,0,0,1,2.1.5,2.46275,2.46275,0,0,1,.9,1.9V72.5Z"
          data-name="path-6"
        />
        <path
          fill="url(#d)"
          fillRule="evenodd"
          d="M88,72.5V76a37.93334,37.93334,0,0,1-.6,6.7,8.5338,8.5338,0,0,1-3.6,5.6h-.1a12.35558,12.35558,0,0,1-6.9,2.5l-1.9.2A9.53981,9.53981,0,0,1,67,87.6a8.779,8.779,0,0,1-1.4-8.8v-.1a9.24834,9.24834,0,0,1,6.5-5.8l7.5-2a4.29583,4.29583,0,0,0,3.3-4.2V61.6h0V40.1a1.61246,1.61246,0,0,0-.6-1.3,1.73546,1.73546,0,0,0-1.4-.3L48.9,45a2.37562,2.37562,0,0,0-2,2.4V81.6h0v2.5a37.93323,37.93323,0,0,1-.6,6.7,8.53375,8.53375,0,0,1-3.6,5.6h-.1a12.35561,12.35561,0,0,1-6.9,2.5l-1.8.1A9.53981,9.53981,0,0,1,26,95.6a8.779,8.779,0,0,1-1.4-8.8v-.1a9.24834,9.24834,0,0,1,6.5-5.8l7.5-2a4.29583,4.29583,0,0,0,3.3-4.2V69.6h0V29.9a3.71845,3.71845,0,0,1,3-3.6L85,19a3.14378,3.14378,0,0,1,2.1.5,2.46275,2.46275,0,0,1,.9,1.9V72.5Z"
          data-name="path-6"
        />
        <path
          fill="#fff"
          id="appleMusicSvgMark"
          fillRule="evenodd"
          d="M88,72.5V76a37.93334,37.93334,0,0,1-.6,6.7,8.5338,8.5338,0,0,1-3.6,5.6h-.1a12.35558,12.35558,0,0,1-6.9,2.5l-1.9.2A9.53981,9.53981,0,0,1,67,87.6a8.779,8.779,0,0,1-1.4-8.8v-.1a9.24834,9.24834,0,0,1,6.5-5.8l7.5-2a4.29583,4.29583,0,0,0,3.3-4.2V61.6h0V40.1a1.61246,1.61246,0,0,0-.6-1.3,1.73546,1.73546,0,0,0-1.4-.3L48.9,45a2.37562,2.37562,0,0,0-2,2.4V81.6h0v2.5a37.93323,37.93323,0,0,1-.6,6.7,8.53375,8.53375,0,0,1-3.6,5.6h-.1a12.35561,12.35561,0,0,1-6.9,2.5l-1.8.1A9.53981,9.53981,0,0,1,26,95.6a8.779,8.779,0,0,1-1.4-8.8v-.1a9.24834,9.24834,0,0,1,6.5-5.8l7.5-2a4.29583,4.29583,0,0,0,3.3-4.2V69.6h0V29.9a3.71845,3.71845,0,0,1,3-3.6L85,19a3.14378,3.14378,0,0,1,2.1.5,2.46275,2.46275,0,0,1,.9,1.9V72.5Z"
          data-name="path-6"
        />
      </g>
    </svg>
  );
};
