import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class WebsiteNav extends Component<any> {
  render() {
    return (
      <nav className="dashboard__website--navigation">
        <NavLink
          to="/admin/website/about"
          className={({ isActive }) =>
            isActive ? "dashboard__website--activeSubMenu" : ""
          }
          onClick={this.props.closeMenu}
        >
          About
        </NavLink>
        <NavLink
          to="/admin/website/education"
          className={({ isActive }) =>
            isActive ? "dashboard__website--activeSubMenu" : ""
          }
          onClick={this.props.closeMenu}
        >
          Education
        </NavLink>
        <NavLink
          to="/admin/website/skills"
          className={({ isActive }) =>
            isActive ? "dashboard__website--activeSubMenu" : ""
          }
          onClick={this.props.closeMenu}
        >
          Skills
        </NavLink>
        <NavLink
          to="/admin/website/experiences"
          className={({ isActive }) =>
            isActive ? "dashboard__website--activeSubMenu" : ""
          }
          onClick={this.props.closeMenu}
        >
          Experiences
        </NavLink>
        <NavLink
          to="/admin/website/projects"
          className={({ isActive }) =>
            isActive ? "dashboard__website--activeSubMenu" : ""
          }
          onClick={this.props.closeMenu}
        >
          Projects
        </NavLink>
      </nav>
    );
  }
}
