import React, { FunctionComponent, ReactNode, MouseEvent } from "react";

interface Props {
  children?: ReactNode;
  addOne: (e: MouseEvent<HTMLParagraphElement>) => void;
}

const ProjectWrapper: FunctionComponent<Props> = ({ children, addOne }) => (
  <div className="adminProjects">
    {children}
    <p className="btn-round btn-midBlue" onClick={addOne}>
      <span>+</span>
    </p>
  </div>
);

export default ProjectWrapper;
