import React, { Component, Fragment } from 'react';

interface Props {
  imagePos: number;
  images: Array<string>;
  onclose: () => void;
}

export default class LightBox extends Component<Props> {
  state = {
    imagePos: parseInt(this.props.imagePos.toString()),
  };

  componentDidMount() {
    document.addEventListener('keydown', this.arrowKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.arrowKeyPress);
    clearTimeout();
  }

  arrowKeyPress = (e: KeyboardEvent) => {
    if (e.keyCode === 37) {
      this.goLeft();
    } else if (e.keyCode === 39) {
      this.goRight();
    } else if (e.keyCode === 27) {
      this.props.onclose();
    }
  };

  goLeft = async () => {
    let newPos: number;

    if (this.state.imagePos - 1 < 0) {
      newPos = this.props.images.length - 1;
    } else {
      newPos = this.state.imagePos - 1;
    }

    await this.setState({
      imagePos: newPos,
    });
  };

  goRight = async () => {
    let newPos: number;

    if (this.state.imagePos + 1 > this.props.images.length - 1) {
      newPos = 0;
    } else {
      newPos = this.state.imagePos + 1;
    }

    await this.setState({
      imagePos: newPos,
    });
  };

  render() {
    const { imagePos } = this.state;
    const { images } = this.props;

    return (
      <div className="lightBox" id="lightBox">
        <i className="fa fa-times" onClick={this.props.onclose} />
        <div className="content">
          <div className="content__control--left">
            <i className="fa fa-chevron-left" onClick={this.goLeft} />
          </div>
          <div className="content__image">
            {images.map((image, i) => (
              <Fragment key={i}>
                {i === imagePos ? (
                  <img src={image} alt="In the LightBox" width={window.innerWidth} height="auto" />
                ) : null}
              </Fragment>
            ))}
          </div>
          <div className="content__control--right">
            <i className="fa fa-chevron-right" onClick={this.goRight} />
          </div>
        </div>
      </div>
    );
  }
}
