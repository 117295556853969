import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import base64 from "base-64";

export const InvavalidPermsission403 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const msg = params.get("msg");

    if (msg !== null) {
      const data = base64.decode(msg);
      setMsg(data);
    }
  }, [location, msg]);

  return (
    <div className="errorpage403">
      <div className="errorpage403__content">
        <h2>403 &mdash; Forbidden</h2>
        {msg.length > 0 ? <div>{msg}</div> : <div>Not enough permissions</div>}
        <nav className="errorpage403__navigation">
          <p
            onClick={() => {
              navigate(-1);
            }}
            className="errorpage403__navigation--item"
          >
            Back to previous page
          </p>
          <Link className="errorpage403__navigation--item" to="/admin">
            Back to admin
          </Link>
          <Link className="errorpage403__navigation--item" to="/">
            Back to home
          </Link>
        </nav>
      </div>
    </div>
  );
};
