import React, { Component, createRef, MouseEvent } from "react";

import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import LogoCard from "../common/LogoCard";
import ContactFormConatiner from "./ContactFormCotainer";
import { Footer } from "../common/Footer";
import LandingHamburgerMenu from "./LandingHamburgerMenu";
import LandingMenuOverlay from "./LandingMenuOverlay";
import PopUpWindow from "../common/PopUpWindow";
import EducationContent from "./EducationContent";
import About from "./About/About";
import ExperienceContent from "./ExperienceContent";
import FadeInScrollContainer from "../common/FadeInScrollContainer";
import ProjectsController from "./Projects/ProjectsController";
import { getProjectById, setLoading } from "../../actions/contentActions";
import { ProjectsDataInterface } from "../../interfaces/Projects";
import ProjectContent from "./ProjectContent";
import LoadingSpinner from "../common/LoadingSpinner";
import { MyCV } from "../MyCv";

interface StateToProps {
  isAuth: boolean;
  isLoading: boolean;
  projectById: ProjectsDataInterface;
  getProjectById: (id: string) => void;
  setLoading: (state: boolean) => void;
}

class App extends Component<StateToProps, any> {
  private menuSideBarRef = createRef<HTMLDivElement>();

  private contactSuccessRef = createRef<HTMLDivElement>();

  private contactErrorRef = createRef<HTMLDivElement>();

  private hamburgerRef = createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);

    this.state = {
      showMenuButton: false,
      showSkipButton: false,
      showMenu: false,
      openEducationPopUp: false,
      openExperiencesPopUp: false,
      openProjectPopUp: false,
      openDonwloadCv: false,
      openedProjectID: "",
      showFooter: true,
      sendMessage: false,
    };
  }

  /* ########################### */
  /* Rect methods */
  /* ########################### */

  componentDidMount() {
    this.portfolioResizer();
    window.addEventListener("scroll", this.menuBarRender);
    window.addEventListener("scroll", this.menuBarRender);
    window.addEventListener("resize", this.portfolioResizer);
    document.title = "Joel Mühlena";
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.menuBarRender);
    window.removeEventListener("resize", this.portfolioResizer);
  }

  UNSAFE_componentWillUpdate(np: any, ns: any) {
    // if showMenu changes menuDisplay sould be changed
    if (!ns.showMenu) {
      this.changeMenuDispaly();
    }
  }

  /* ########################### */
  /* Menu methods */
  /* ########################### */
  onMenuButtonClick = async (): Promise<void> => {
    await this.setState((state: any) => ({
      showMenu: !state.showMenu,
    }));
    this.changeMenuDispaly();
  };

  changeMenuDispaly = () => {
    const current = this.menuSideBarRef.current!;

    try {
      if (this.state.showMenu) {
        current.style.transform = "translateX(0)";
        current.style.opacity = "1";
        current.style.visibility = "visible";
      } else {
        current.style.transform = "translateX(100%)";
        current.style.opacity = "0";
        current.style.visibility = "hidden";
      }

      const hamburgerMenu = this.hamburgerRef.current!;
      if (this.state.showMenu) {
        hamburgerMenu.classList.add("barElement--clicked");
      } else {
        hamburgerMenu.classList.remove("barElement--clicked");
      }
    } catch (error) {}
  };

  menuBarRender = async (): Promise<void> => {
    if (window.scrollY > window.innerHeight - 10) {
      if (!this.state.showMenuButton) {
        this.setState((state: any) => ({ showMenuButton: true }));
      }
    } else {
      await this.setState({ showMenuButton: false, showMenu: false });
    }
  };

  /* ########################### */
  /* SVGIcon methods */
  /* ########################### */

  onContactFormSuccessClose = (): void => {
    const successDiv = this.contactSuccessRef.current!;
    successDiv.style.visibility = "hidden";
    successDiv.style.opacity = "0";
    successDiv.style.transform = "translateY(-25rem)";
  };

  onContactFormErrorClose = (): void => {
    const errorDiv = this.contactErrorRef.current!;
    errorDiv.style.visibility = "hidden";
    errorDiv.style.opacity = "0";
    errorDiv.style.transform = "translateY(-25rem)";
  };

  onSendMessage = () => {
    this.setState({
      sendMessage: true,
    });
  };

  afterSendMessage = () => {
    this.setState({
      sendMessage: false,
    });
  };

  /* ########################### */
  /* Menu methods                                     */
  /* ########################### */
  onClickMenuEntry = () => {
    this.setState({ showMenu: false });
  };

  /* ########################### */
  /* other methods                                     */
  /* ########################### */
  onClickPopUpEducation = () => {
    this.setState({ openEducationPopUp: true });
    document.body.style.overflow = "hidden";
  };

  onClickClosePopUpEducation = () => {
    this.setState({ openEducationPopUp: false });
    document.body.style.overflowX = "hidden";
    document.body.style.overflowY = "auto";
  };

  onClickPopUpExperiences = () => {
    this.setState({ openExperiencesPopUp: true });
    document.body.style.overflow = "hidden";
  };

  onClickClosePopUpExperiences = () => {
    this.setState({ openExperiencesPopUp: false });
    document.body.style.overflowX = "hidden";
    document.body.style.overflowY = "auto";
  };

  onClickPopUpProjects = (e: MouseEvent<HTMLButtonElement>) => {
    this.setState({
      openProjectPopUp: true,
      openedProjectID: e.currentTarget.dataset.id,
    });

    this.props.getProjectById(e.currentTarget.dataset.id as string);
    this.props.setLoading(true);
    document.body.style.overflow = "hidden";
  };

  onClickClosePopUpProjects = () => {
    this.setState({ openProjectPopUp: false, openedProjectID: "" });
    document.body.style.overflowX = "hidden";
    document.body.style.overflowY = "auto";
  };

  onClickPopUpCV = () => {
    this.setState({ openDonwloadCv: true });
    document.body.style.overflow = "hidden";
  };

  onClickClosePopUpCV = () => {
    this.setState({ openDonwloadCv: false });
    document.body.style.overflowX = "hidden";
    document.body.style.overflowY = "auto";
  };

  /* ########################### */
  /* Portfolio resizer listener Resize
  checks if there are more than one line and if so
  changes the grid height                                     */
  /* ########################### */
  portfolioResizer = () => {
    const cardWrapper = document.getElementById("portfolioLandingWrapper")!;
    const card = document.getElementsByClassName("fadeInScrollCard")[0];
    const cardContainer = document.getElementById("portfolioLandingContainer")!;

    if (window.innerWidth > 900) {
      if (
        cardWrapper.childElementCount * card.clientWidth >
        cardWrapper.clientWidth
      ) {
        if (!cardContainer.classList.contains("mz")) {
          cardContainer.classList.add("mz");
          cardContainer.classList.remove("ez");
        }
      } else if (!cardContainer.classList.contains("ez")) {
        cardContainer.classList.add("ez");
        cardContainer.classList.remove("mz");
      }
    } else if (!cardContainer.classList.contains("ez")) {
      cardContainer.classList.add("ez");
      cardContainer.classList.remove("mz");
    }
  };

  /* ########################### */
  /* Render methods */
  /* ########################### */
  render() {
    const { isAuth } = this.props;
    const {
      openEducationPopUp,
      openExperiencesPopUp,
      openProjectPopUp,
      openDonwloadCv,
      showFooter,
      sendMessage,
    } = this.state;

    return (
      <React.Fragment>
        {/* Transition for the menu button */}
        <CSSTransition
          in={this.state.showMenuButton}
          timeout={300}
          classNames="menuButtonLanding"
          unmountOnExit
        >
          <LandingHamburgerMenu
            onclick={this.onMenuButtonClick}
            hamburgerRef={this.hamburgerRef}
          />
        </CSSTransition>

        {/* Transition for the educaytion Pop up */}
        <CSSTransition
          in={openEducationPopUp}
          classNames="popUpWindowPortfolio"
          timeout={600}
          unmountOnExit
        >
          <PopUpWindow
            onClickCloseIcon={this.onClickClosePopUpEducation}
            heading="Education"
          >
            <EducationContent />
          </PopUpWindow>
        </CSSTransition>

        <CSSTransition
          in={openDonwloadCv}
          classNames="popUpWindowPortfolio"
          timeout={600}
          unmountOnExit
        >
          <PopUpWindow
            onClickCloseIcon={this.onClickClosePopUpCV}
            heading="Download CV"
          >
            <MyCV />
          </PopUpWindow>
        </CSSTransition>

        {/* Transition for the experience Pop up */}
        <CSSTransition
          in={openExperiencesPopUp}
          classNames="popUpWindowPortfolio"
          timeout={600}
          unmountOnExit
        >
          <PopUpWindow
            onClickCloseIcon={this.onClickClosePopUpExperiences}
            heading="Experiences"
          >
            <ExperienceContent />
          </PopUpWindow>
        </CSSTransition>

        {/* Transition for the project Pop up */}
        <CSSTransition
          in={openProjectPopUp}
          classNames="popUpWindowPortfolio"
          timeout={600}
          unmountOnExit
        >
          <PopUpWindow
            onClickCloseIcon={this.onClickClosePopUpProjects}
            heading={this.props.projectById.name}
            extraClasses={["projectContent"]}
          >
            <ProjectContent
              project={this.props.projectById}
              isLoading={this.props.isLoading}
            />
          </PopUpWindow>
        </CSSTransition>

        {/* Menu overlay with all Points */}
        <LandingMenuOverlay
          refMenu={this.menuSideBarRef}
          onentryclick={this.onClickMenuEntry}
        />

        {/* ########################### */
        /* Landing page */
        /* ########################### */}
        <section id="start" className="jmPage jmPage--landing">
          <header className="header">
            <nav className="header__nav">
              <div className="header__nav--entry">
                <a href="#about">About</a>
              </div>
              <div className="header__nav--entry">
                <a href="#portfolio">Learn more</a>
              </div>
              <div className="header__nav--entry">
                <a href="#projects">Projects</a>
              </div>
              <div className="header__nav--entry">
                <a href="#contact">Contact</a>
              </div>
              <div className="header__nav--entry">
                <a href="https://blog.joel.muehlena.de">Blog</a>
              </div>
            </nav>
          </header>
          <h1 className="landing__header">
            Joel Mühlena
            <span className="landing__header--underText">
              Coding \w{" "}
              <span
                role="img"
                aria-label="heart"
                style={{ display: "inline-block" }}
              >
                ❤️
              </span>
            </span>
          </h1>
          <p className="landing__text">
            {/* Continue Scrolling text with moving gradient */}
            <svg width="100vw" height="100%">
              <rect x="0" y="0" fill="transparent" width="100%" height="100%" />

              <text x="50%" textAnchor="middle" y="50%" fill="url(#pattern)">
                <tspan x="50%">Let's start scrolling</tspan>
                <tspan x="50%" dy="25">
                  and learn more about me.
                </tspan>
              </text>

              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0">
                  <stop offset="0%" stopColor="#FFD603" />
                  <stop offset="50%" stopColor="#FF8709" />
                  <stop offset="100%" stopColor="#FFD603" />
                </linearGradient>

                <pattern
                  id="pattern"
                  x="0"
                  y="0"
                  width="300%"
                  height="100%"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="150%"
                    height="100%"
                    fill="url(#gradient)"
                  >
                    <animate
                      attributeType="XML"
                      attributeName="x"
                      from="0"
                      to="150%"
                      dur="2s"
                      repeatCount="indefinite"
                    />
                  </rect>
                  <rect
                    x="-150%"
                    y="0"
                    width="150%"
                    height="100%"
                    fill="url(#gradient)"
                  >
                    <animate
                      attributeType="XML"
                      attributeName="x"
                      from="-150%"
                      to="0"
                      dur="2s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </pattern>
              </defs>
            </svg>
          </p>
        </section>

        {/* ########################### */
        /* About page */
        /* ########################### */}
        <section id="about" className="jmPage about">
          <About openCVWindow={this.onClickPopUpCV} />
        </section>

        {/* ########################### */
        /* Portfolio page */
        /* ########################### */}
        <section id="portfolio" className="jmPage portfolio">
          <div className="portfolioContainer" id="portfolioLandingContainer">
            <h2 className="heading-secondary heading-secondary--portfolio">
              Not enough? U want to know more about me?
            </h2>
            <div className="heading heading--mobile">
              <h2 className="heading-secondary heading-secondary--about">
                Portfolio
              </h2>
              <div className="u-seperator" />
            </div>
            <div className="portfolio__cards" id="portfolioLandingWrapper">
              <FadeInScrollContainer className={["fadeInScrollCard"]}>
                <LogoCard
                  iconClasses={["fa", "fa-lightbulb-o"]}
                  text="Interested in what I can do? Then take a look at my skills"
                  linkName="Take me there"
                  link="/skills"
                />
              </FadeInScrollContainer>
              <FadeInScrollContainer className={["fadeInScrollCard"]}>
                <LogoCard
                  iconClasses={["fa", "fa-book"]}
                  text="Want to know how my Education looks like? View my Education"
                  linkName="Show me"
                  onClick={this.onClickPopUpEducation}
                />
              </FadeInScrollContainer>
              <FadeInScrollContainer className={["fadeInScrollCard"]}>
                <LogoCard
                  iconClasses={["fa", "fa-certificate"]}
                  text="Where have I been yet? Look at my experiences"
                  linkName="Show me"
                  onClick={this.onClickPopUpExperiences}
                />
              </FadeInScrollContainer>
            </div>
          </div>
        </section>

        {/* ########################### */
        /* projects page */
        /* ########################### */}

        <section id="projects" className="jmPage projects">
          <div className="heading heading--desktop">
            <h2 className="heading-secondary heading-secondary--projects">
              Sounds nice to now but what did you create already?
            </h2>
            <div className="u-seperator" />
          </div>
          <div className="heading heading--mobile">
            <h2 className="heading-secondary heading-secondary--projects">
              Projects
            </h2>
            <div className="u-seperator" />
          </div>
          <ProjectsController openPopUp={this.onClickPopUpProjects} />
        </section>

        {/* ########################### */
        /* contact page */
        /* ########################### */}
        <section id="contact" className="jmPage jmPage--contact contact">
          <div className="contactWrapper">
            <div id="contactSuccess" ref={this.contactSuccessRef}>
              <i
                className="fa fa-times-circle  contact__success--circle"
                onClick={this.onContactFormSuccessClose}
              />
              <div>
                <h3 style={{ color: "#2ecc71" }}>Success</h3>
                <p>Your message has been sent. Nice!</p>
              </div>
            </div>
            <div id="contactSuccess" ref={this.contactErrorRef}>
              <i
                className="fa fa-times-circle  contact__success--circle"
                onClick={this.onContactFormErrorClose}
              />
              <div>
                <h3 style={{ color: "#e74c3c" }}>Error</h3>
                <p>
                  Ohhh no there was an error. I'm very sorry.
                  <br />
                  Please check your input and then try again
                </p>
              </div>
            </div>
            {sendMessage ? (
              <div id="sendMessage-spinner">
                <LoadingSpinner />
              </div>
            ) : null}
            <ContactFormConatiner
              successRef={this.contactSuccessRef}
              errorRef={this.contactErrorRef}
              onSend={this.onSendMessage}
              afterSend={this.afterSendMessage}
            />
            <h2 className="heading-secondary heading-secondary--contact">
              Contact
            </h2>
          </div>
        </section>
        <Footer isAuth={isAuth} showFooter={showFooter} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isAuth: state.user.isAuthenticated,
  projectById: state.content.projectById,
  isLoading: state.content.isLoading,
});

export default connect(mapStateToProps, { getProjectById, setLoading })(App);
