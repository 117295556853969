import React from "react";
import ReactDOM from "react-dom";
import { Provider, shallowEqual, useSelector } from "react-redux";
import axios from "axios";

import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import store from "./store";
import storeDev from "./storeDev";

import App from "./components/Landing/App";

//Import general css
import "./sass/main.scss";

import * as serviceWorker from "./serviceWorker";
import DashboardStart from "./components/Admin/Dashboard/DashboardStart";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import { Wrapper as PortfolioWrapper } from "./components/PortfolioWrapper";

import Logout from "./components/Admin/Login/Logout";
import ErrorMessage from "./components/common/ErrorMessage";
import SuccessMessage from "./components/common/SuccessMessage";
import { setCurrentUser } from "./actions/usersActions";
import { constants } from "./utils/constants";
import { setError } from "./actions/errorActions";
import setAuthToken from "./utils/setAuthToken";
import jwt_decode from "jwt-decode";
import CookieBanner from "./components/CookieBanner";
import { getCookie } from "./utils/cookieUtils";
import CookieInfo from "./components/CookieInfo";
import { LoginV2 } from "./components/LoginV2/LoginV2";
import { LoadingCredentialData } from "./components/common/LoadingCredentialData";
import { SET_CREDENTIAL_DATA_LOADING } from "./actions/types";
import { InvavalidPermsission403 } from "./components/InvalidPermission403";

import base64 from "base-64";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  function (response) {
    if (response.headers["newAccessToken"]) {
      let token = response.headers["newAccessToken"];
      setAuthToken(token);
      const decoded = jwt_decode(token);
      process.env.NODE_ENV === "production"
        ? store.dispatch(setCurrentUser(decoded) as any)
        : storeDev.dispatch(setCurrentUser(decoded) as any);
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

axios
  .get(`${constants.AUTH_URL}/auth/token?silent=true`)
  .then((res: any) => {
    if (!(res.data.msg && res.data.msg === "silentError")) {
      setAuthToken(res.data.token);
      const decoded = jwt_decode(res.data.token);
      process.env.NODE_ENV === "production"
        ? store.dispatch(setCurrentUser(decoded) as any)
        : storeDev.dispatch(setCurrentUser(decoded) as any);
    }

    process.env.NODE_ENV === "production"
      ? store.dispatch({ type: SET_CREDENTIAL_DATA_LOADING, payload: false })
      : storeDev.dispatch({
          type: SET_CREDENTIAL_DATA_LOADING,
          payload: false,
        });
  })
  .catch((err) => {
    if (err.response) {
      process.env.NODE_ENV === "production"
        ? store.dispatch(setError(err.response.data) as any)
        : storeDev.dispatch(setError(err.response.data) as any);
    }
    process.env.NODE_ENV === "production"
      ? store.dispatch({ type: SET_CREDENTIAL_DATA_LOADING, payload: false })
      : storeDev.dispatch({
          type: SET_CREDENTIAL_DATA_LOADING,
          payload: false,
        });
  });

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  (document.body as any).style.height = window.innerHeight;
});

const setCookieBanner = () => {
  let expires = new Date(new Date().getTime() + 5.184e9).toUTCString();
  document.cookie =
    "CookieBannerAgreement=true; expires=" + expires + ";path=/";

  try {
    (document.querySelector(".jm-cookieBanner")! as HTMLElement).style.display =
      "none";
  } catch (e) {}
};

export const isCookieBanner = (): boolean => {
  if (getCookie("CookieBannerAgreement")) {
    return true;
  }

  return false;
};

const RouterComp = () => {
  const isCredentialDataLoading = useSelector(
    (state: any) => state.user.isCredentialDataLoading,
    shallowEqual
  );

  return (
    <BrowserRouter>
      <React.Fragment>
        <div className="feedbackMessagesContainer">
          <ErrorMessage />
          <SuccessMessage />
        </div>
        <Routes>
          {/*Basic Landing one pager*/}
          <Route path="/" element={<App />} />
          {/*Wrapper decides which PortfolioPage is going to be rendered*/}
          <Route path="skills" element={<PortfolioWrapper />} />
          {/*Impressum und Datenschutz und Cookie infos*/}
          <Route path="impressum" element={<Impressum />} />
          <Route path="datenschutz" element={<Datenschutz />} />
          <Route path="cookies" element={<CookieInfo />} />
          {/*Login route*/}
          <Route path="login" element={<LoginV2 />} />
          {/*Logout Route*/}
          <Route path="logout" element={<Logout />} />
          {/*Invalid permission Route*/}
          <Route path="403" element={<InvavalidPermsission403 />} />
          {/*Admindashboard startpage*/}
          {isCredentialDataLoading ? (
            <Route path="/admin" element={<LoadingCredentialData />} />
          ) : (
            <Route element={<PrivateRouteOutlet />}>
              <Route path="admin/*" element={<DashboardStart />} />
            </Route>
          )}
        </Routes>
        {!isCookieBanner() && <CookieBanner onAccept={setCookieBanner} />}
      </React.Fragment>
    </BrowserRouter>
  );
};

const PrivateRouteOutlet = () => {
  const callbackUrl = base64.encode(window.location.href);
  const isAuth = useSelector((state: any) => state.user.isAuthenticated);

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?callbackUrl=${callbackUrl}`} />
  );
};

if ((window as any).REACT_APP_IS_STAGING) {
  console.log(
    "React Env is staging",
    (window as any).REACT_APP_IS_STAGING,
    process.env.NODE_ENV
  );
}

ReactDOM.render(
  <Provider store={process.env.NODE_ENV === "production" ? store : storeDev}>
    <RouterComp />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
