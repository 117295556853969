import React, { FunctionComponent, useCallback, MouseEvent } from "react";

interface Props {
  length: number;
  active?: boolean;
  activeIndicatorRef: React.RefObject<HTMLSpanElement>;
  changeActiveElement: (index: number) => void;
}

export const IndicatorV2: FunctionComponent<Props> = ({
  length,
  active = false,
  activeIndicatorRef,
  changeActiveElement,
}) => {
  const onIndicatorClick = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      const index = e.currentTarget.dataset.index;
      if (index) {
        console.log("bullet", index);
        changeActiveElement(parseInt(index));
      }
    },
    [changeActiveElement]
  );

  return (
    <div>
      <div className="jmCaroussel__indicator">
        <span
          className="jmCaroussel__indicatorBullet--activeCursor"
          ref={activeIndicatorRef}
        />
        {[...Array(length).keys()].map((_, i) => (
          <span
            className={`jmCaroussel__indicatorBullet ${active ? "active" : ""}`}
            data-index={i}
            onClick={onIndicatorClick}
          ></span>
        ))}
      </div>
    </div>
  );
};
