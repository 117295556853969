import { CLEAR_ERROR, SET_ERROR } from './types';

export const setError = (message: any) => ({
  type: SET_ERROR,
  payload: message,
});

export const clearError = () => ({
  type: CLEAR_ERROR
});
