import React, {
  FunctionComponent,
  ReactNode,
  Fragment,
  MouseEvent,
} from "react";

import {
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  AppleMusic as AppleMusicIcon,
} from "./../../../img/svg/socialIcons/Icons";

import aboutPic from "../../../img/dev/aboutPic.jpg";

interface Props {
  children: ReactNode;
  showSkipAbout: boolean;
  openCVWindow: () => void;
  onClickSVG: (event: MouseEvent<HTMLOrSVGElement>) => void;
}

const AboutWrap: FunctionComponent<Props> = (props) => {
  const { children, showSkipAbout, onClickSVG, openCVWindow } = props;

  return (
    <Fragment>
      <div className="heading">
        <h2 className="heading-secondary heading-secondary--about">About</h2>
        <div className="u-seperator" />
      </div>
      <div className="about__leftSide">
        <div className="heading heading--mobile">
          <h2 className="heading-secondary heading-secondary--about">About</h2>
          <div className="u-seperator" />
        </div>
        <h2 className="about__leftSide--text">
          Here is some basic info about me
        </h2>
        <img
          className="about__leftSide--image"
          alt="Joel Mühlena"
          src={aboutPic}
        />
        <span onClick={openCVWindow} className="about__leftSide--dlcv">
          Download CV
        </span>
        <div className="about__socialIcons">
          {/*All social media icons*/}
          <InstagramIcon onclick={onClickSVG} />
          <TwitterIcon onclick={onClickSVG} />
          <AppleMusicIcon onclick={onClickSVG} />
        </div>
        <a href="#portfolio" className="about__skipButton">
          Skip
        </a>
      </div>
      <div className="about__rightSide">{children}</div>
      {showSkipAbout && (
        <a className="about__fixedSkip" href="#portfolio">
          <span className="fa fa-angle-down" />
        </a>
      )}
    </Fragment>
  );
};

export default AboutWrap;
