import React, { Component, MouseEvent } from "react";

import ProjectsWrapper from "./ProjectsWrapper";
import { connect } from "react-redux";

import { getProjects } from "../../../actions/contentActions";
import { ProjectsDataInterface } from "../../../interfaces/Projects";

interface Props {
  openPopUp: (e: MouseEvent<HTMLButtonElement>) => void;
}

interface DispatchToProps {
  getProjects: () => void;
}

interface StateToProps {
  projects: Array<ProjectsDataInterface>;
  errors: any;
}

type ReduxProps = DispatchToProps & StateToProps;

class ProjectsController extends Component<ReduxProps & Props> {
  componentDidMount() {
    this.props.getProjects();
  }

  mouseEnterProjectCard = (e: MouseEvent<HTMLDivElement>) => {
    e.currentTarget.classList.add("hover");
  };

  mouseLeaveProjectCard = (e: MouseEvent<HTMLDivElement>) => {
    e.currentTarget.classList.remove("hover");
  };

  clickProjectCard = (e: MouseEvent<HTMLDivElement>) => {
    e.currentTarget.classList.toggle("hover");
  };

  render() {
    return (
      <ProjectsWrapper
        error={
          this.props.errors.projectserror ? this.props.errors.projectserror : ""
        }
        projectData={this.props.projects}
        mouseEnter={this.mouseEnterProjectCard}
        mouseLeave={this.mouseLeaveProjectCard}
        click={this.clickProjectCard}
        openPopUp={this.props.openPopUp}
      />
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    projects: state.content.projects,
    errors: state.errors,
  };
};

export default connect(mapStateToProps, { getProjects })(ProjectsController);
