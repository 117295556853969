import React, { FunctionComponent } from "react";

const CookieInfo: FunctionComponent = () => {
  return (
    <div>
      <p>
        Currently the site only uses cookies that are relevant for selected
        registered users. These are informed about the cookies used.
        Furthermore, when accepting the cookie usage, a cookie is created and
        saved so that the banner is not displayed again each time.
      </p>
    </div>
  );
};

export default CookieInfo;
