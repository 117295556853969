import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  getMessages,
  delMessage,
  markAsReadMessage,
} from "../../../../actions/messagesActions";
import isEmpty from "../../../../functions/isEmpty";
import { Message } from "../../../../interfaces";
import onlyNLetters from "../../../../functions/onlyNLetters";
import Modal from "../../../common/Modal";
import { withPermissions } from "../../../withPermissions";

interface PropsFromState {
  messages: Array<Message>;
}

interface PropsFromDispatch {
  getMessages: () => void;
  delMessage: (id: any) => void;
  markAsReadMessage: (id: any, isRead: boolean) => void;
}

type ReduxProps = PropsFromState & PropsFromDispatch;

type State = {
  openIndex: number;
  isAllSelected: boolean;
  showModal: boolean;
  deleteIndex: number;
  selected: Array<{
    index: number;
    id: string;
  }>;
};

class Messages extends Component<ReduxProps, State> {
  private activeAccRef = createRef<HTMLDivElement>();

  state = {
    openIndex: -1,
    isAllSelected: false,
    showModal: false,
    deleteIndex: -1,
    selected: [],
  };

  componentDidMount() {
    this.props.getMessages();
    document.addEventListener("click", this.checkAccClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.checkAccClick);
  }

  checkAccClick = (e: any) => {
    try {
      if (this.activeAccRef.current!.contains(e.target)) {
        return;
      } else {
        this.setState({ openIndex: -1 });
      }
    } catch (err) {}
  };

  onMarkAsRead = (e: any, index: number) => {
    let id: any = this.props.messages[index]._id;
    let isRead: boolean = this.props.messages[index].isRead;
    this.props.markAsReadMessage(id, !isRead);
  };

  onDeleteMessageText = (e: React.MouseEvent<HTMLElement>, index: number) => {
    this.setState({
      showModal: true,
      deleteIndex: index,
    });
  };

  onContinueDelete = () => {
    const { deleteIndex, selected } = this.state;

    if (deleteIndex !== -1) {
      let id: any = this.props.messages[deleteIndex]._id;
      this.props.delMessage(id);
    } else {
      for (let i = 0; i < this.props.messages.length; i++) {
        if (
          selected.find(
            (el: { index: number; id: string }) => el.index === i
          ) !== undefined
        ) {
          let id = (
            selected.find(
              (el: { index: number; id: string }) => el.index === i
            )! as any
          ).id;
          this.props.delMessage(id);
        }
      }
      this.setState({
        selected: [],
      });
    }

    this.onAbortDelete();
  };

  onAbortDelete = () => {
    this.setState({
      showModal: false,
      deleteIndex: -1,
    });
  };

  openAccordion = (e: any, index: number) => {
    if (e.target.classList.contains("btn") || e.target.tagName === "INPUT") {
      return;
    } else {
      if (index === this.state.openIndex) {
        this.setState({ openIndex: -1 });
      } else {
        if (!this.props.messages[index].isRead) {
          this.onMarkAsRead(e, index);
        }
        this.setState({ openIndex: index });
      }
    }
  };

  onSelectAll = () => {
    const { isAllSelected } = this.state;
    const { messages } = this.props;

    if (!isAllSelected) {
      let array: any = [];
      for (let i = 0; i < messages.length; i++) {
        array.push({ index: i, id: messages[i]._id.toString() });
      }
      this.setState((state) => ({
        selected: array,
        isAllSelected: !state.isAllSelected,
      }));
    } else {
      this.setState((state) => ({
        selected: [],
        isAllSelected: !state.isAllSelected,
      }));
    }
  };

  onSelect = (e: any, index: number) => {
    const { selected } = this.state;
    const { messages } = this.props;

    if (
      selected.find(
        (el: { index: number; id: string }) => el.index === index
      ) === undefined
    ) {
      this.setState((state) => {
        let obj = { index, id: messages[index]._id.toString() };

        return {
          selected: state.selected.concat(obj),
        };
      });
    } else {
      this.setState((state) => {
        const selectedIndex = state.selected.findIndex(
          (el) => el.index === index
        );
        let array = state.selected;
        array.splice(selectedIndex, 1);
        return {
          selected: array,
        };
      });
    }
  };

  render() {
    const { messages } = this.props;
    const { isAllSelected, showModal } = this.state;

    return (
      <div className="dashboard__messages">
        {showModal && (
          <Modal
            text="Are you sure you want to delete this item(s)"
            onAbort={this.onAbortDelete}
            onContinue={this.onContinueDelete}
          />
        )}
        <div>
          <div className="btn-group">
            <button className="btn" onClick={this.onSelectAll}>
              {isAllSelected ? `Unselect all` : `Select all`}
            </button>
            <button
              className="btn btn-danger"
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                this.onDeleteMessageText(e, -1)
              }
            >
              Delete
            </button>
            <button
              className="btn btn-midBlue"
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                this.props.getMessages()
              }
            >
              Refresh
            </button>
          </div>
        </div>
        <div className="messages__content">
          {!isEmpty(messages) &&
            messages.map((el: Message, index: number) => (
              <div
                className={`messages__akkordion ${
                  this.state.openIndex === index
                    ? "messages__akkordion--open"
                    : ""
                }`}
                key={el._id}
                ref={this.state.openIndex === index ? this.activeAccRef : null}
              >
                <div
                  className={`messages__group ${
                    el.isRead ? "messages__group--read" : ""
                  } `}
                  onClick={(e) => {
                    this.openAccordion(e, index);
                  }}
                >
                  <div className="btn-group">
                    <button
                      className="btn btn-danger"
                      onClick={(e: React.MouseEvent<HTMLElement>) =>
                        this.onDeleteMessageText(e, index)
                      }
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-midBlue"
                      onClick={(e: React.MouseEvent<HTMLElement>) =>
                        this.onMarkAsRead(e, index)
                      }
                    >
                      {!el.isRead ? "Read" : "Unread"}
                    </button>
                  </div>
                  <p>{el.name}</p>
                  <p>{onlyNLetters(el.text, 40)}...</p>
                  <p>{el.email}</p>
                  <input
                    type="checkbox"
                    data-id={el._id}
                    checked={
                      //console.log(this.state.selected.filter((el :any) => el.id === index))
                      this.state.selected.filter(
                        (el: any) => el.index === index
                      ).length > 0
                        ? true
                        : false
                    }
                    onChange={(e) => this.onSelect(e, index)}
                  />
                </div>
                {this.state.openIndex === index ? (
                  <div className="messages__akkordion--content">
                    <p>{el.text}</p>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  messages: state.messages,
});

export default withPermissions(
  connect(mapStateToProps, { getMessages, delMessage, markAsReadMessage })(
    Messages
  ),
  "jmWeb:message:get"
);
