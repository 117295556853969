import { SET_ERROR_MESSAGE, SET_SUCCESS_MESSAGE } from "../actions/types";

const initialState = {
  errorMsg: "",
  successMsg: "",
};

// eslint-disable-next-line
export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMsg: action.payload,
      };
    default:
      return state;
  }
}
