import React, { FunctionComponent, ReactNode, RefObject } from "react";
import { CSSTransition } from "react-transition-group";

interface Props {
  children: ReactNode;
  showSuccess: boolean;
  onSave: () => void;
  onAbort: () => void;
  editorRef: RefObject<HTMLDivElement>;
  onClose: () => void;
}

const AboutWrap: FunctionComponent<Props> = ({
  children,
  onSave,
  onAbort,
  editorRef,
  showSuccess,
  onClose,
}) => {
  return (
    <div className="adminAbout">
      <div className="adminAbout__wrapperEditor">
        <CSSTransition
          in={showSuccess}
          classNames="adminAboutSuccess"
          timeout={600}
          unmountOnExit
        >
          <div id="aboutSuccess">
            <i className="fa fa-close" onClick={onClose} />
            <div>
              <i className="fa fa-check" />
              <h3>Success</h3>
              <p>Everthing is saved! Cool</p>
            </div>
          </div>
        </CSSTransition>
        <div
          contentEditable={true}
          className="adminAbout__editor"
          suppressContentEditableWarning={true}
          ref={editorRef}
        >
          {children}
        </div>
      </div>
      <div className="adminAbout__controlButtons">
        <button
          onClick={onSave}
          className="btn btn-success"
          disabled={showSuccess}
        >
          Save
        </button>
        <button
          onClick={onAbort}
          className="btn btn-danger"
          disabled={showSuccess}
        >
          Abort
        </button>
      </div>
    </div>
  );
};

export default AboutWrap;
