import React, { FormEvent, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "../../actions/usersActions";
import base64 from "base-64";
import { setError } from "../../actions/errorActions";
import { CSSTransition } from "react-transition-group";

export const LoginV2 = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const errors = useSelector((state: any) => state.errors);
  const [isShowError, setIsShowError] = useState(false);
  const isAuthenticated = useSelector(
    (state: any) => state.user.isAuthenticated,
    shallowEqual
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const submitForm = (e: FormEvent) => {
    e.preventDefault();
    const userData = { username, password };
    dispatch(login(userData));
  };

  useEffect(() => {
    const callbackUrlB64 = new URLSearchParams(location.search).get(
      "callbackUrl"
    );

    if (isAuthenticated) {
      if (callbackUrlB64) {
        window.location.href = base64.decode(callbackUrlB64);
      } else {
        navigate("/admin");
      }
    }
  }, [isAuthenticated, location, navigate]);

  useEffect(() => {
    if (
      (typeof errors === "object" && (errors.username || errors.password)) ||
      (typeof errors === "string" && errors.length > 0)
    ) {
      setIsShowError(true);
    } else {
      console.log("no error");
      setIsShowError(false);
    }
  }, [isShowError, errors]);

  return (
    <div className="loginPage">
      <CSSTransition
        timeout={300}
        classNames="errorDivLogin"
        in={isShowError}
        unmountOnExit
      >
        <div className="errorMsg">
          <div className="content">
            <div className="errorMsg__username">{errors.username}</div>
            <div className="errorMsg__password">{errors.password}</div>
            {typeof errors === "string" ? (
              <div className="errorMsg__other">{errors}</div>
            ) : null}
          </div>
          <div className="closeIcon" onClick={(e) => dispatch(setError(""))}>
            <i className="fa fa-times" />
          </div>
        </div>
      </CSSTransition>
      <div className="loginForm">
        <h2 className="loginForm__header">Login</h2>
        <form className="loginForm__form" onSubmit={submitForm}>
          <div className="inputContainer">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
            />
          </div>
          <div className="inputContainer">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </div>
          <input type="submit" value="Login" />
        </form>
      </div>
    </div>
  );
};
