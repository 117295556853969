import {
  GET_ABOUT_TEXT,
  SET_ABOUT_TEXT,
  GET_SKILLS,
  GET_EDUCATION,
  GET_EXPERIENCE,
  SET_LOADING,
  SET_SAVING,
  GET_PROJECTS,
  GET_PROJECTS_BY_ID,
} from "../actions/types";

const initialState: any = {
  isLoading: false,
  isSaving: false,
  about: "",
  skills: [],
  experience: [],
  education: [],
  projects: [],
  projectById: {},
};

// eslint-disable-next-line
export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_ABOUT_TEXT:
      return {
        ...state,
        isLoading: false,
        about: action.payload,
      };
    case SET_ABOUT_TEXT:
      return {
        ...state,
        about: action.payload,
      };
    case GET_SKILLS:
      return {
        ...state,
        isLoading: false,
        skills: action.payload,
      };
    case GET_EDUCATION:
      return {
        ...state,
        isLoading: false,
        education: action.payload,
      };
    case GET_EXPERIENCE:
      return {
        ...state,
        isLoading: false,
        experience: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_SAVING:
      return {
        ...state,
        isSaving: action.payload,
      };
    case GET_PROJECTS:
      return {
        ...state,
        isLoading: false,
        projects: action.payload,
      };
    case GET_PROJECTS_BY_ID:
      return {
        ...state,
        isLoading: false,
        projectById: action.payload,
      };
    default:
      return state;
  }
}
