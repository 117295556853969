import classNames from "classnames";
import React, { FunctionComponent, ReactNode } from "react";

interface Props {
  children: ReactNode;
  active?: boolean;
  onClick?: (e: any) => void;
  index?: number;
}

export const CarousselCard: FunctionComponent<Props> = ({
  children,
  active = false,
  onClick,
  index,
}) => {
  return (
    <div
      onClick={onClick ? onClick : () => {}}
      data-index={index ?? ""}
      className={`jmCaroussel__card ${classNames({
        active: active,
      })}`}
    >
      <div className="jmCaroussel__cardContent">{children}</div>
    </div>
  );
};
