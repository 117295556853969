import React, { Component } from "react";
import { connect } from "react-redux";
import { getEducation, setLoading } from "../../actions/contentActions";
import { educationData } from "../../interfaces/Education";
import LoadingSpinner from "../common/LoadingSpinner";

interface DispatchToProps {
  getEducation: () => void;
  setLoading: (state: boolean) => void;
  educationContent: Array<educationData>;
  isLoading: boolean;
}

class EducationContent extends Component<DispatchToProps, {}> {
  componentDidMount() {
    this.props.setLoading(true);
    this.props.getEducation();
  }

  graduatedState = (state: number): string => {
    switch (state) {
      case 0:
        return "Not graduated";
      case 1:
        return "not yet graduated (still on it)";
      case 2:
        return "graduated";
      default:
        return "nothing here";
    }
  };

  render() {
    return (
      <div className="popUpWindow__content--educationContent">
        {this.props.isLoading ? (
          <span className="popUpWindow__spinner">
            <LoadingSpinner />
          </span>
        ) : null}
        {!this.props.isLoading &&
          this.props.educationContent.length > 0 &&
          this.props.educationContent.map((eduEntry, i) => (
            <div className="popUpWindow__entry" key={i}>
              <h3 className="popUpWindow__entry--h3">
                {eduEntry.schoolName} / {eduEntry.description}
              </h3>
              <div className="popUpWindow__entry--line">
                <p className="popUpWindow__entry--fp">
                  {eduEntry.schoolLocation}
                </p>
                <p className="popUpWindow__entry--sepPoints">&bull;</p>
                <div className="popUpWindow__entry--sp">
                  <p>{eduEntry.from}</p> &nbsp;
                  <span className="m-dash-edu">&mdash;</span>&nbsp;
                  {eduEntry.current ? <p>now</p> : <p>{eduEntry.to}</p>}
                </div>
                <p className="popUpWindow__entry--sepPoints">&bull;</p>
                <p className="popUpWindow__entry--tp">
                  {this.graduatedState(eduEntry.graduated)}
                </p>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.content.isLoading,
    educationContent: state.content.education,
  };
};

export default connect(mapStateToProps, { getEducation, setLoading })(
  EducationContent
);
