import React, { RefObject } from "react";

type Props = {
  name: string;
  placeholder: string;
  required?: boolean;
  value: string;
  refForFocus?: RefObject<HTMLTextAreaElement>;
  onchange(e: any): Promise<void>;
  onkeydown?: (e: any) => void;
};

const TextArea: React.SFC<Props> = ({
  name,
  placeholder,
  required,
  onchange,
  value,
  onkeydown,
  refForFocus
}) => {
  return (
    <div className="formGroup">
      <textarea
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={onchange}
        onKeyDown={onkeydown}
        value={value}
        ref={refForFocus ? refForFocus : null}
      />
    </div>
  );
};

export default TextArea;
