import React, { Component, createRef, RefObject } from "react";
import validator from "validator";

import { connect } from "react-redux";
import TextArea from "../common/TextArea";

import { setMessage } from "../../actions/messagesActions";
import { clearError } from "../../actions/errorActions";
import InputField from "../common/InputField";

interface DispatchToProps {
  setMessage: (data: any) => void;
  clearError: () => void;
}

interface StateToProps {
  errors: any;
}

interface Props {
  successRef?: RefObject<HTMLDivElement>;
  errorRef?: RefObject<HTMLDivElement>;
  onSend: () => any;
  afterSend: () => void;
}

class ContactFormCotainer extends Component<
  Props & DispatchToProps & StateToProps,
  {}
> {
  private contactFormRef = createRef<HTMLInputElement>();

  state = {
    name: "",
    email: "",
    message: "",
    isSending: false,
    handleSubmit: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (!this.state.handleSubmit && this.state.isSending) {
      if (this.props.errors.messageerror) {
        this.setState({ handleSubmit: true, isSending: false });
        this.props.clearError();
        this.showErrorMessage();
      } else if (this.props.errors.nomessageerror) {
        this.setState({ handleSubmit: true, isSending: false });
        this.props.clearError();
        this.showSuccessMessage();
      }
    }
  }

  /* ########################### */
  /* Contact methods */
  /* ########################### */
  onContactFormChange = async (e: any): Promise<void> => {
    await this.setState({
      [e.target.name]: e.target.value,
    });

    const current = this.contactFormRef.current!;
    if (this.validateContactForm()) {
      current.style.backgroundColor = "#2ecc71";
      current.disabled = false;
    } else {
      current.style.backgroundColor = "rgba(231, 76, 60, .6)";
      current.disabled = true;
    }
  };

  onSubmitContactForm = (e: any) => {
    e.preventDefault();
    const { name, email, message } = this.state;

    this.props.onSend();

    this.setState({ isSending: true });

    const current = this.contactFormRef.current!;

    this.props.setMessage({ name, email, text: message });

    setTimeout(() => {
      this.setState({
        name: "",
        email: "",
        message: "",
        sending: false,
      });
      current.style.backgroundColor = "rgba(231, 76, 60, .6)";
      current.disabled = true;
    }, 600);
    clearTimeout();

    this.props.afterSend();
  };

  showErrorMessage = () => {
    const errorDiv = this.props.errorRef!.current!;
    errorDiv.style.visibility = "visible";
    errorDiv.style.opacity = "1";
    errorDiv.style.transform = "translateY(0)";
    this.setState({ handleSubmit: false });
  };

  showSuccessMessage = () => {
    const successDiv = this.props.successRef!.current!;
    successDiv.style.visibility = "visible";
    successDiv.style.opacity = "1";
    successDiv.style.transform = "translateY(0)";
    this.setState({ handleSubmit: false });
  };

  validateContactForm = (): boolean => {
    const { email, name, message } = this.state;

    if (
      validator.isEmail(email) &&
      !validator.isEmpty(name) &&
      !validator.isEmpty(message)
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { name, email, message } = this.state;

    return (
      <form
        onSubmit={this.onSubmitContactForm}
        id="contactFormLanding"
        noValidate
      >
        <InputField
          name="name"
          placeholder="Name"
          value={name}
          label="Name"
          type="text"
          required
          onchange={this.onContactFormChange}
        />
        <InputField
          value={email}
          name="email"
          placeholder="Email"
          label="Email"
          type="email"
          required
          onchange={this.onContactFormChange}
        />
        <TextArea
          name="message"
          placeholder="What do you want to say me?"
          value={message}
          required
          onchange={this.onContactFormChange}
        />
        <div className="formGroup">
          <input
            className="contact__submit"
            type="submit"
            name="submit"
            value={"Submit"}
            ref={this.contactFormRef}
            disabled
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: any) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, { setMessage, clearError })(
  ContactFormCotainer
);
