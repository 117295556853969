import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Skills } from "./Skills/Skills";

export const Wrapper = () => {
  const [toRender, setToRender] = useState<null | JSX.Element>(null);
  const location = useLocation();

  useEffect(() => {
    let url = location.pathname.split("/");
    changeRender(url[url.length - 1].trim());
  });

  const changeRender = (compName: string) => {
    let render = null;

    if (compName === "skills") {
      render = <Skills />;
    } else {
      render = null;
    }

    setToRender(render);
  };

  return (
    <div className="background__utils">
      <div className="wrapper__utils">{toRender}</div>
    </div>
  );
};
