import React, { FunctionComponent, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSkills, setLoading } from "../../actions/contentActions";
import { SkillGroup } from "../../interfaces/Skills";
import LoadingSpinner from "../common/LoadingSpinner";
import { CarousselV2 } from "../common/CarousselV2/CarousselV2";

export const Skills: FunctionComponent = () => {
  const dispatch = useDispatch();

  const {
    skills: skillData,
    isLoading,
  }: { skills: Array<SkillGroup>; isLoading: boolean } = useSelector(
    (state: any) => state.content,
    shallowEqual
  );

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getSkills());
  }, [dispatch]);

  return (
    <div className="skillsPage">
      <h1 className="skillsPage__title">Skills</h1>
      <p className="btn-round btn-midBlue backButton">
        <a href="/#portfolio">
          <span>
            <i className="fa fa-angle-left" />
          </span>
        </a>
      </p>

      {isLoading ? (
        <div className="skillsPage__loadingSpinner">
          <LoadingSpinner />
        </div>
      ) : (
        <CarousselV2 infiniteScroll={true} indicator={true}>
          {skillData.map((skill) => (
            <div key={skill._id}>
              <h2>{skill.skillGroupName}</h2>
              <ul>
                {skill.skills.map((skillPoint) => (
                  <li>{skillPoint.skillName}</li>
                ))}
              </ul>
            </div>
          ))}
        </CarousselV2>
      )}
    </div>
  );
};
