import React, { FunctionComponent, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Users from "./Users/Users";
import Messages from "./Messages/Messages";
import { Website } from "./Website/Website";
import { useDispatch } from "react-redux";

import { getMessages } from "../../../actions/messagesActions";
import AccountContoller from "./Account/AccountContoller";
import Start from "./Start/Start";

interface Props {
  layoutType: string;
}

export const DashboardContent: FunctionComponent<Props> = ({
  layoutType,
  ...props
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalID = setInterval(() => {
      dispatch(getMessages());
    }, 180000);

    return () => {
      clearInterval(intervalID);
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Routes>
        <Route path="start" element={<Start />} />
        <Route path="users" element={<Users />} />
        <Route path="messages" element={<Messages />} />
        <Route path="account" element={<AccountContoller />} />
        <Route
          path="website/*"
          element={<Website {...props} layoutType={layoutType} />}
        />
      </Routes>
    </React.Fragment>
  );
};
