import React, { FunctionComponent, MouseEvent } from "react";
import { ProjectsDataInterface } from "../../../interfaces/Projects";
import ProjectCard from "./ProjectCard";

interface Props {
  error: string;
  projectData: Array<ProjectsDataInterface>;
  mouseEnter: (e: MouseEvent<HTMLDivElement>) => void;
  mouseLeave: (e: MouseEvent<HTMLDivElement>) => void;
  click: (e: MouseEvent<HTMLDivElement>) => void;
  openPopUp: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ProjectWrapper: FunctionComponent<Props> = ({
  error,
  projectData,
  mouseEnter,
  mouseLeave,
  click,
  openPopUp,
}) => {
  return (
    <div className="projectsSection">
      {error ? <h3 className="projectsSection__errorMsg">{error}</h3> : null}

      <div className="projectsSection__cards">
        {projectData.length > 0
          ? projectData.map((project, i) => (
              <ProjectCard
                project={project}
                counter={i}
                key={i}
                mouseEnter={mouseEnter}
                mouseLeave={mouseLeave}
                click={click}
                openPopUp={openPopUp}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default ProjectWrapper;
