import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { setErrorMsg } from "../../actions/feedbackMessagesActions";

interface StateToProps {
  errorMsg: string;
}

interface mapDispatchToProps {
  setErrorMsg: (msg: string) => void;
}

class ErrorMessage extends Component<StateToProps & mapDispatchToProps> {
  onClickCloseMsg = () => {
    this.props.setErrorMsg("");
  };

  render() {
    return (
      <Fragment>
        {this.props.errorMsg.length > 0 ? (
          <div className="feedbackMessage feedbackMessage__error">
            <div className="placeholder" />
            <p>{this.props.errorMsg}</p>
            <i className="fa fa-times" onClick={this.onClickCloseMsg} />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    errorMsg: state.feedbackMessages.errorMsg,
  };
};

export default connect(mapStateToProps, { setErrorMsg })(ErrorMessage);
