import React from "react";

export const LoadingCredentialData = () => {
  return (
    <div id="fetchCredentialsScreen">
      <h2>Fetching permission</h2>
      <p>
        You are trying to access a ressource with restricted access. Please wait
        until the credentials are checked. You'll be redirected automatically
      </p>
    </div>
  );
};
