import React, { Component, createRef, ReactChild } from "react";
import { Link } from "react-router-dom";

import { getMessages } from "../../../../actions/messagesActions";

import { Navigation } from "../Navigation";
import { connect } from "react-redux";
import NotificationCounter from "../../../common/NotificationCounter";
import { AuthInterface } from "../../../../interfaces/Auth";

interface StateToProps {
  messagesCount: number;
  auth: AuthInterface;
}

interface DispatchToProps {
  getMessages: () => void;
}

interface Props {
  children: ReactChild;
}

type ReduxProps = StateToProps & DispatchToProps & Props;

class DLayout extends Component<ReduxProps, {}> {
  private firstMenuPointRef = createRef<HTMLDivElement>();
  private userMenuRef = createRef<HTMLDivElement>();

  state = {
    menuOpen: true,
    showUserMenu: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.checkUserMenu, false);
    window.addEventListener("resize", this.checkSize, false);
    this.props.getMessages();
    this.checkSize();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.checkUserMenu, false);
    window.removeEventListener("resize", this.checkSize, false);
  }

  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (Object.keys(nextState).includes("menuOpen")) {
      this.openSideBar();
    }
  }

  checkSize = (): void => {
    let size: number = window.innerWidth;

    if (size <= 1000) {
      this.setState({ menuOpen: false });
    } else {
      this.setState({ menuOpen: true });
    }
    //this.openSideBar();
  };

  checkUserMenu = (e: any): void => {
    if (this.userMenuRef.current!.contains(e.target)) {
      return;
    } else {
      this.setState({ showUserMenu: false });
      this.changeUserMenu();
    }
  };

  onMenuBarsClick = async (): Promise<void> => {
    await this.setState({
      menuOpen: !this.state.menuOpen,
    });

    this.openSideBar();
  };

  openSideBar = (): void => {
    if (!this.state.menuOpen) {
      document.querySelector(".dashboard")!.classList.add("dashboard--small");

      document
        .querySelector(".sidebar__topPart")!
        .classList.add("sidebar__topPart--small");

      let allmenuEntries = document.querySelectorAll(
        ".sidebar__navigation--title"
      );
      allmenuEntries.forEach((e) => {
        e.classList.add("sidebar__navigation--title--small");
      });

      let allIcons = document.querySelectorAll(".sidebar__navigation--entry");
      allIcons.forEach((e) => {
        e.classList.add("sidebar__navigation--entry--small");
      });
    } else {
      document
        .querySelector(".dashboard")!
        .classList.remove("dashboard--small");

      document
        .querySelector(".sidebar__topPart")!
        .classList.remove("sidebar__topPart--small");

      let allmenuEntries = document.querySelectorAll(
        ".sidebar__navigation--title"
      );
      allmenuEntries.forEach((e) => {
        e.classList.remove("sidebar__navigation--title--small");
      });

      let allIcons = document.querySelectorAll(".sidebar__navigation--entry");
      allIcons.forEach((e) => {
        e.classList.remove("sidebar__navigation--entry--small");
      });
    }
  };

  onProfileSecClick = async (e: any): Promise<void> => {
    await this.setState({
      showUserMenu: !this.state.showUserMenu,
    });

    this.changeUserMenu();
  };

  changeUserMenu = (): void => {
    if (this.state.showUserMenu) {
      document
        .querySelector(".navigation__profileSec")!
        .classList.add("navigation__profileSec--active");

      (
        document.querySelector(".navigation__profileSec--icon")! as any
      ).style.transform = "rotate(180deg)";

      (
        document.querySelector(".navigation__userMenu")! as any
      ).style.visibility = "visible";
      (document.querySelector(".navigation__userMenu")! as any).style.opacity =
        "1";
      (
        document.querySelector(".navigation__userMenu")! as any
      ).style.transform = "translateX(0)";

      try {
        (
          document.querySelector(
            ".navigation__profileSec--notificationCounter"
          )! as any
        ).style.display = "none";

        (
          document.querySelector(
            ".navigation__userMenu--notificationCounter"
          )! as any
        ).style.display = "flex";
      } catch (err) {}
    } else {
      document
        .querySelector(".navigation__profileSec")!
        .classList.remove("navigation__profileSec--active");

      (
        document.querySelector(".navigation__profileSec--icon")! as any
      ).style.transform = "rotate(0deg)";
      (
        document.querySelector(".navigation__userMenu")! as any
      ).style.visibility = "hidden";
      (document.querySelector(".navigation__userMenu")! as any).style.opacity =
        "0";
      (
        document.querySelector(".navigation__userMenu")! as any
      ).style.transform = "translateX(120%)";

      try {
        (
          document.querySelector(
            ".navigation__profileSec--notificationCounter"
          )! as any
        ).style.display = "flex";
        (
          document.querySelector(
            ".navigation__userMenu--notificationCounter"
          )! as any
        ).style.display = "none";
      } catch (error) {}
    }
  };

  onRemoveActiveTag = (e: any) => {
    try {
      document
        .querySelector(".sidebar__navigation--entry--active")!
        .classList.remove("sidebar__navigation--entry--active");
    } catch (err) {
      console.log("geht nicht");
    }

    try {
      if (e.currentTarget.children.length > 0) {
        for (let i = 0; i < e.currentTarget.children.length; i++) {
          if (
            e.currentTarget.children[i] != null &&
            e.currentTarget.children[i].tagName === "A" &&
            e.currentTarget.children[i].textContent === "Messages"
          ) {
            document
              .querySelector(".sidebar__navigation--entry.messages")!
              .classList.add("sidebar__navigation--entry--active");
          }
        }
      }
    } catch (err) {
      console.log("geht nicht");
    }
  };

  render() {
    const {
      auth: { userInfo },
    } = this.props;

    return (
      <div className="dashboard">
        <aside className="sidebar">
          <div className="sidebar__topPart">
            <h2>Hello, {userInfo.firstName}</h2>
            <div className="sidebar__seperator" />
          </div>
          <Navigation
            firstMenuPointRef={this.firstMenuPointRef}
            type="desktop"
          />
        </aside>
        <header className="navigation">
          <div
            className="navigation__hamburgermenu"
            onClick={this.onMenuBarsClick}
          >
            <div className="navigation__hamburgermenu--bar" />
            <div className="navigation__hamburgermenu--bar" />
            <div className="navigation__hamburgermenu--bar" />
          </div>

          <div className="navigation__profileSec" ref={this.userMenuRef}>
            <div>
              <img
                alt={userInfo.firstName + " " + userInfo.lastName}
                className="navigation__profileSec--profilePic"
                src={userInfo.avatar}
                onClick={this.onProfileSecClick}
              />
              <NotificationCounter
                ownClassNames={["navigation__profileSec--notificationCounter"]}
              />
            </div>
            <div onClick={this.onProfileSecClick}>
              <p>{userInfo.firstName + " " + userInfo.lastName}</p>
            </div>
            <div onClick={this.onProfileSecClick}>
              <i className="fa fa-angle-down navigation__profileSec--icon" />
            </div>
            <div className="navigation__userMenu">
              <div className="navigation__userMenu--entry">
                <Link to="/admin/account" onClick={this.onRemoveActiveTag}>
                  My account
                </Link>
              </div>
              <div
                className="navigation__userMenu--entry"
                onClick={this.onRemoveActiveTag}
              >
                <Link to="/admin/messages">Messages</Link>
                <NotificationCounter
                  ownClassNames={["navigation__userMenu--notificationCounter"]}
                />
              </div>
              {/*<div className="navigation__userMenu--entry">
                <Link to="/admin/settings" onClick={this.onRemoveActiveTag}>
                  Settings
                </Link>
                </div>*/}
              <div className="navigation__userMenu--entry">
                <Link to="/logout" onClick={this.onRemoveActiveTag}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className="dashboard__content">{this.props.children}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  messagesCount: state.messages.length,
  auth: state.user,
});

export default connect(mapStateToProps, { getMessages })(DLayout);
