import React, { FunctionComponent, ChangeEvent } from "react";
import InputField from "../../../../common/InputField";

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onChangeCheck: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: any) => void;
  cancel: () => void;
  name: string;
  description: string;
  locaction: string;
  from: number;
  to: number;
  graduated: string;
  current: boolean;
  errors: any;
}

const EditEducatioWrapper: FunctionComponent<Props> = ({
  onChange,
  onChangeCheck,
  onSubmit,
  cancel,
  name,
  description,
  locaction,
  from,
  to,
  graduated,
  current,
  errors
}) => {
  return (
    <div className="adminEducation__edit">
      <div className="content">
        <form noValidate onSubmit={onSubmit}>
          <div>
            <InputField
              type="text"
              name="name"
              value={name}
              onchange={onChange}
              placeholder="Bleidenstatdt Gymnasium"
            />
            {/*<input type="text" name="name" value={name} onChange={onChange} />*/}
            <InputField
              type="text"
              name="description"
              value={description}
              onchange={onChange}
              placeholder="Berufliches Gymnasium"
            />
          </div>
          <div>
            <InputField
              type="text"
              name="location"
              value={locaction}
              onchange={onChange}
              placeholder="Frankfurt"
            />
          </div>
          <div>
            <InputField
              type="number"
              name="from"
              value={from}
              onchange={onChange}
            />
            <InputField
              type="number"
              name="to"
              value={to}
              onchange={onChange}
              disabled={current}
            />
            <input
              type="checkbox"
              name="current"
              checked={current}
              onChange={onChangeCheck}
            />
          </div>
          <div>
            <select
              name="graduated"
              onChange={onChange}
              value={graduated}
              disabled={current}
            >
              <option value="-1">Please select one</option>
              <option value="0">not graduated</option>
              <option value="1">not yet graduated (for current)</option>
              <option value="2">graduated</option>
              {/*0: not graduated 1: not yet graduated (for current) 2: graduated*/}
            </select>
          </div>
          <div className="buttons">
            <input type="submit" value="Save" className="btn btn-success" />
            <input
              type="button"
              value="Cancel"
              onClick={cancel}
              className="btn btn-danger"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEducatioWrapper;
