import React, { Component, Fragment, MouseEvent } from "react";
import { ProjectsDataInterface } from "../../interfaces/Projects";
import LoadingSpinner from "../common/LoadingSpinner";
import LightBox from "../common/LightBox";

interface Props {
  project: ProjectsDataInterface;
  isLoading: boolean;
}

export default class ProjectContent extends Component<Props> {
  state = {
    lightBox: false,
    lightBoxImage: 0,
  };

  openLightBox = (e: MouseEvent<HTMLImageElement>) => {
    this.setState({
      lightBox: true,
      lightBoxImage: e.currentTarget.dataset.pos,
    });
  };

  closeLightBox = () => {
    this.setState({
      lightBox: false,
      lightBoxImage: 0,
    });
  };

  render() {
    const { project, isLoading } = this.props;
    const { lightBox, lightBoxImage } = this.state;

    return (
      <div>
        {lightBox && (
          <LightBox
            images={project.images}
            imagePos={lightBoxImage}
            onclose={this.closeLightBox}
          />
        )}

        {this.props.isLoading ? (
          <span className="popUpWindow__spinner">
            <LoadingSpinner />
          </span>
        ) : null}
        {!isLoading && (
          <Fragment>
            <div>
              <h3>Description: </h3>
              <p>{project.description}</p>
            </div>
            <div>
              <h3>General infos:</h3>
              <div className="projectContent__generalContent">
                <h4>How long did I work on this project?</h4>
                <p>
                  I worked on this project from {project.from} to{" "}
                  {project.current ? "now" : project.to}
                </p>
                {project.skills.length > 0 && (
                  <Fragment>
                    <h4>
                      Which skills
                      {project.current ? " are " : " were "}
                      used for this project?
                    </h4>
                    <ul>
                      {project.skills.map((skill, i) => (
                        <li key={i}>{skill}</li>
                      ))}
                    </ul>
                  </Fragment>
                )}
              </div>
            </div>
            {project.urls.length > 0 && (
              <div>
                <h3>
                  U want to see this project live or may visit other links which
                  belong to this project?
                </h3>
                {project.urls.map((url, i) => (
                  <a
                    href={url}
                    key={i}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="projectContent__showcaseLink"
                  >
                    {url}
                  </a>
                ))}
              </div>
            )}
            <div>
              <h3>Gallery:</h3>
              <div className="projectContent__gallery">
                {project.images.length > 0 ? (
                  project.images.map((image, i) => (
                    <img
                      src={image}
                      key={i}
                      alt={`Project: ${project.name}`}
                      data-pos={i}
                      onClick={this.openLightBox}
                    />
                  ))
                ) : (
                  <p>
                    Currently there are no images associated with this project
                  </p>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
