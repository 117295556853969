import {
  GET_ALLUSERS,
  SET_CREDENTIAL_DATA_LOADING,
  SET_CURRENT_USER,
} from "../actions/types";
import isEmpty from "../functions/isEmpty";

const initialState = {
  isAuthenticated: false,
  isCredentialDataLoading: true,
  userInfo: {},
  allUsers: [],
};

// eslint-disable-next-line
export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_ALLUSERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        userInfo: action.payload,
      };
    case SET_CREDENTIAL_DATA_LOADING:
      return {
        ...state,
        isCredentialDataLoading: action.payload,
      };
    default:
      return state;
  }
}
