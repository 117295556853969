import React, { Component } from "react";
import { connect } from "react-redux";

import { logout } from "../../../actions/usersActions";

class Logout extends Component<any, any> {
  componentDidMount() {
    this.props.logout();
    this.props.history.push("/");
  }

  render() {
    return <div></div>;
  }
}

export default connect(null, { logout })(Logout);
