import React, { useEffect, useState } from "react";

import Education from "../Website/Education/Education";
import { About } from "./About/About";
import { Skills } from "./Skills/Skills";
import ExperiencesController from "./Experiences/ExperiencesController";
import ProjectsController from "./Projects/ProjectsController";
import { useParams } from "react-router-dom";

export const SubMenu = () => {
  const [toRender, setToRender] = useState<JSX.Element | null>(null);
  const { name } = useParams();

  useEffect(() => {
    let toRenderInternal = null;

    if (name === "about") {
      toRenderInternal = <About />;
    } else if (name === "skills") {
      toRenderInternal = <Skills />;
    } else if (name === "education") {
      toRenderInternal = <Education />;
    } else if (name === "experiences") {
      toRenderInternal = <ExperiencesController />;
    } else if (name === "projects") {
      toRenderInternal = <ProjectsController />;
    }

    setToRender(toRenderInternal);
  }, [name]);

  return <React.Fragment>{toRender}</React.Fragment>;
};

export default SubMenu;
