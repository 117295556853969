import { useSelector } from "react-redux";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useRoleVerification = (
  permission: string,
  autoRedirect: boolean = false,
  redirectPath: string = "/403"
) => {
  const user = useSelector((state: any) => state.user);

  const [data, setData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const getVerification = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await axios.post(
        "https://auth.joel.muehlena.de/auth/verifyRole",
        { permission, roleName: user.userInfo.roles }
      );

      if (result.status === 200) {
        setData(true);
      } else {
        setData(false);
      }
    } catch (error) {
      setData(false);
    } finally {
      setIsLoading(false);
    }
  }, [permission, user.userInfo.roles]);

  useEffect(() => {
    if (!data && autoRedirect && !isLoading) {
      navigate(redirectPath);
    }
  }, [autoRedirect, data, isLoading, navigate, redirectPath]);

  useEffect(() => {
    if (user.isAuthenticated) {
      getVerification();
    }
  }, [user, getVerification]);

  return { data, isLoading };
};
