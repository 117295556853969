import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getExperience, setLoading } from "../../actions/contentActions";
import { ExperieceDataInterface } from "../../interfaces/Experience";
import { getMonthNameById } from "../../utils/getMonthNames";
import LoadingSpinner from "../common/LoadingSpinner";

interface DispatchToProps {
  getExperience: () => void;
  setLoading: (state: boolean) => void;
}

interface stateToProps {
  experience: Array<ExperieceDataInterface>;
  isLoading: boolean;
}

interface State {
  isSorted: boolean;
  sortedDataArray: Array<ExperieceDataInterface> | [];
}

type ReduxProps = DispatchToProps & stateToProps;

class ExperienceContent extends Component<ReduxProps, State> {
  state = {
    isSorted: false,
    sortedDataArray: [],
  };

  sortExperienceEntries = (
    a: ExperieceDataInterface,
    b: ExperieceDataInterface
  ) => {
    const aa = new Date(a.to);
    const bb = new Date(b.to);

    if (a.current && !b.current) {
      return -1;
    }

    if (!a.current && b.current) {
      return 1;
    }

    if (a.current && b.current) {
      return 0;
    }

    if (aa > bb) {
      return -1;
    }

    if (aa < bb) {
      return 1;
    }

    return 0;
  };

  componentDidUpdate() {
    if (
      !this.props.isLoading &&
      !this.state.isSorted &&
      this.props.experience.length > 0
    ) {
      let nArray: Array<ExperieceDataInterface> = ([] as any).concat(
        this.props.experience
      );

      nArray.sort(this.sortExperienceEntries);

      this.setState({
        isSorted: true,
        sortedDataArray: nArray,
      });
    }
  }

  componentDidMount() {
    this.props.setLoading(true);
    this.props.getExperience();
  }

  experienceState = (state: number): string => {
    switch (state) {
      case 0:
        return "Internship";
      case 1:
        return "Job";
      default:
        return "nothing here";
    }
  };

  render() {
    return (
      <div className="popUpWindow__content--experienceContent">
        {this.props.isLoading ? (
          <span className="popUpWindow__spinner">
            <LoadingSpinner />
          </span>
        ) : null}
        {!this.props.isLoading &&
          this.state.sortedDataArray.length > 0 &&
          this.state.sortedDataArray.map((xpEntry: ExperieceDataInterface) => (
            <div className="popUpWindow__entry" key={xpEntry._id}>
              <h3 className="popUpWindow__entry--h3">
                {xpEntry.companyName} / {xpEntry.companyLocation}
              </h3>
              <div className="popUpWindow__entry--line">
                <p className="popUpWindow__entry--fp">{xpEntry.title}</p>
                <p className="popUpWindow__entry--sepPoints">&bull;</p>
                <div className="popUpWindow__entry--sp">
                  <p>
                    {getMonthNameById(new Date(xpEntry.from).getMonth())}&nbsp;
                    {new Date(xpEntry.from).getFullYear()}
                  </p>{" "}
                  &nbsp;
                  {xpEntry.current === false ||
                  xpEntry.current === undefined ? (
                    <Fragment>
                      <p>&mdash;</p>&nbsp;
                      <p>
                        {getMonthNameById(new Date(xpEntry.to).getMonth())}
                        &nbsp;
                        {new Date(xpEntry.to).getFullYear()}
                      </p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <p>&mdash;</p>&nbsp;
                      <p>Now</p>
                    </Fragment>
                  )}
                </div>
                <p className="popUpWindow__entry--sepPoints">&bull;</p>
                <p className="popUpWindow__entry--tp">
                  {this.experienceState(xpEntry.type)}
                </p>
              </div>
              <div className="popUpWindow__entry--xpDesc">
                {xpEntry.description}
              </div>
            </div>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    experience: state.content.experience,
    isLoading: state.content.isLoading,
  };
};

export default connect(mapStateToProps, {
  getExperience,
  setLoading,
})(ExperienceContent);
