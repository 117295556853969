import React, {
  FunctionComponent,
  ReactNode,
  MouseEvent,
  ChangeEvent,
} from "react";
import { ExperieceDataInterface } from "../../../../../interfaces/Experience";
import InputField from "../../../../common/InputField";

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onSubmit: (e: MouseEvent<HTMLFormElement>) => void;
  cancel: () => void;
  data: ExperieceDataInterface | undefined;
  name: string;
  title: string;
  description: string;
  location: string;
  from_month: number;
  from_year: number;
  to_month: number;
  to_year: number;
  current: boolean;
  type: number;
  errors: any;
  children?: ReactNode;
}

const EditExperienceWrapper: FunctionComponent<Props> = ({
  errors,
  onSubmit,
  onChange,
  name,
  description,
  location,
  from_month,
  from_year,
  to_month,
  to_year,
  current,
  type,
  cancel,
  title,
}) => {
  return (
    <div className="adminExperience__edit">
      <div className="content">
        <form noValidate onSubmit={onSubmit}>
          <div>
            <InputField
              type="text"
              name="name"
              value={name}
              onchange={onChange}
              placeholder="Jack Wolfskin"
            />
            <InputField
              type="text"
              name="title"
              value={title}
              onchange={onChange}
              placeholder="Aushilfe IT"
            />
            {/*<input type="text" name="name" value={name} onChange={onChange} />*/}
            <InputField
              type="text"
              name="description"
              value={description}
              onchange={onChange}
              placeholder="A short description"
            />
          </div>
          <div>
            <InputField
              type="text"
              name="location"
              value={location}
              onchange={onChange}
              placeholder="Frankfurt"
            />
          </div>
          <div>
            <InputField
              type="number"
              name="from_month"
              value={from_month}
              onchange={onChange}
              data-type="date"
            />
            <InputField
              type="number"
              name="from_year"
              value={from_year}
              onchange={onChange}
              data-type="date"
            />
            <InputField
              type="number"
              name="to_month"
              value={to_month}
              onchange={onChange}
              data-type="date"
            />
            <InputField
              type="number"
              name="to_year"
              value={to_year}
              onchange={onChange}
              data-type="date"
            />
            <input
              type="checkbox"
              name="current"
              checked={current}
              value={current.toString()}
              onChange={onChange}
            />
          </div>
          <div>
            <select name="type" onChange={onChange} value={type}>
              <option value="-1">Please select one</option>
              <option value="0">Internship</option>
              <option value="1">Job</option>
            </select>
          </div>
          <div className="buttons">
            <input type="submit" value="Save" className="btn btn-success" />
            <input
              type="button"
              value="Cancel"
              onClick={cancel}
              className="btn btn-danger"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditExperienceWrapper;
