import React, { Component } from "react";

import DLayout from "./desktopLayout/DLayout";
import { DashboardContent } from "./DashboardContent";
import MLayout from "./mobileLayout/MLayout";

type State = {
  layoutType: string;
};

class DashboardStart extends Component<{}, State> {
  state = {
    layoutType: "desktop",
  };

  componentDidMount() {
    window.addEventListener("resize", this.checkSize, false);
    this.checkSize();
    document.title = "Admin - Joel Mühlena";
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkSize, false);
  }

  checkSize = () => {
    let size: number = window.innerWidth;
    if (size < 800) {
      this.setState({ layoutType: "mobile" });
    } else {
      this.setState({ layoutType: "desktop" });
    }
  };

  render() {
    const { layoutType } = this.state;

    return (
      <React.Fragment>
        {layoutType === "desktop" ? (
          <DLayout>
            <DashboardContent layoutType={layoutType} />
          </DLayout>
        ) : (
          <MLayout>
            <DashboardContent layoutType={layoutType} />
          </MLayout>
        )}
      </React.Fragment>
    );
  }
}

export default DashboardStart;
