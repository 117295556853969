import React, { FunctionComponent, ChangeEvent, MouseEvent } from 'react';
import InputField from '../../../../common/InputField';

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onChangeArrayItem: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onChangeCheck: (e: ChangeEvent<HTMLInputElement>) => void;
  onAddImage: (e: MouseEvent<HTMLButtonElement>) => void;
  onRemImage: (e: MouseEvent<HTMLElement>) => void;
  onAddUrl: (e: MouseEvent<HTMLButtonElement>) => void;
  onRemUrl: (e: MouseEvent<HTMLElement>) => void;
  onAddSkill: (e: MouseEvent<HTMLButtonElement>) => void;
  onRemSkill: (e: MouseEvent<HTMLElement>) => void;
  onSubmit: (e: any) => void;
  cancel: () => void;
  name: string;
  shortDescription: string;
  description: string;
  showcaseImage: string;
  images: Array<string>;
  from: number;
  to: number;
  current: boolean;
  urls: Array<string>;
  skills: Array<string>;
}

const EditProjectWrapper: FunctionComponent<Props> = ({
  name,
  onChange,
  onChangeArrayItem,
  onChangeCheck,
  onAddImage,
  onRemImage,
  onAddUrl,
  onRemUrl,
  onAddSkill,
  onRemSkill,
  onSubmit,
  cancel,
  showcaseImage,
  from,
  to,
  current,
  images,
  skills,
  urls,
}) => (
  <div className="adminProjects__edit">
    <div className="adminProjects__edit--content">
      <form noValidate onSubmit={onSubmit}>
        <InputField type="text" name="name" value={name} onchange={onChange} />
        <InputField type="text" name="showcaseImage" value={showcaseImage} onchange={onChange} />
        <div className="date">
          <InputField type="number" name="from" value={from} onchange={onChange} />
          <InputField type="number" name="to" value={to} onchange={onChange} disabled={current} />
          <label htmlFor="current">
            <input type="checkbox" name="current" checked={current} onChange={onChangeCheck} />
            Current
          </label>
        </div>
        <div className="images">
          {images.length > 0
            ? images.map((image, i) => (
              <div key={i} className="adminProjects__edit--flexGroup">
                <InputField
                  name={`image-${i}`}
                  type="text"
                  value={image}
                  onchange={onChangeArrayItem}
                  data-pos={i}
                  data-url={image}
                  data-type="image"
                />
                <i className="fa fa-times-circle" data-pos={i} onClick={onRemImage} />
              </div>
            ))
            : null}
          <button onClick={onAddImage}>Add</button>
        </div>
        <div className="urls">
          {urls.length > 0
            ? urls.map((url, i) => (
              <div key={i} className="adminProjects__edit--flexGroup">
                <InputField
                  name={`url-${i}`}
                  type="text"
                  value={url}
                  onchange={onChangeArrayItem}
                  data-pos={i}
                  data-type="url"
                />
                <i className="fa fa-times-circle" data-pos={i} onClick={onRemUrl} />
              </div>
            ))
            : null}
          <button onClick={onAddUrl}>Add</button>
        </div>
        <div className="skills">
          {skills.length > 0
            ? skills.map((skill, i) => (
              <div key={i} className="adminProjects__edit--flexGroup">
                <InputField
                  name={`skill-${i}`}
                  type="text"
                  value={skill}
                  onchange={onChangeArrayItem}
                  data-pos={i}
                  data-type="skill"
                />
                <i className="fa fa-times-circle" data-pos={i} onClick={onRemSkill} />
              </div>
            ))
            : null}
          <button onClick={onAddSkill}>Add</button>
        </div>
        <div className="buttons">
          <input type="submit" value="Save" className="btn btn-success" />
          <input type="button" value="Cancel" onClick={cancel} className="btn btn-danger" />
        </div>
      </form>
    </div>
  </div>
);

export default EditProjectWrapper;
