import React, { FunctionComponent, Fragment } from "react";
import base64 from "base-64";

import { Link } from "react-router-dom";
import {
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  AppleMusic as AppleMusicIcon,
} from "./../../img/svg/socialIcons/Icons";
import { constants } from "../../utils/constants";
//import { constants } from "../../utils/constants";

let clickSocialIcon = (e: any): void => {
  let service: string;
  if (e.target.tagName === "path") {
    service = e.target.parentElement.dataset.service;
  } else {
    service = e.target.dataset.service;
  }

  let url: string = "";
  switch (service) {
    case "twitter":
      url = "https://twitter.com/joel_muehlena";
      break;
    case "applemusic":
      url = "https://music.apple.com/profile/joel_muehlena";
      break;
    case "instagram":
      url = "https://www.instagram.com/joel_muehl/";
      break;
  }
  Object.assign(document.createElement("a"), {
    target: "_blank",
    href: url,
  }).click();
};

interface Props {
  isAuth: boolean;
  showFooter: boolean;
}

export const Footer: FunctionComponent<Props> = ({ isAuth, showFooter }) => {
  return (
    <Fragment>
      {showFooter ? (
        <footer className="footer">
          <div className="footer__metaNav">
            <div className="u-seperator" />
            <nav className="footer__metaNav--navigation">
              <div className="footer__metaNav--entry">
                <Link to="/datenschutz">Datenschutz</Link>
              </div>
              <div className="footer__metaNav--entry">
                <Link to="/impressum">Impressum</Link>
              </div>
              {/*<div className="footer__metaNav--entry">
                <a href={constants.BLOG_URL}>Blog</a>
              </div>*/}
              <div className="footer__metaNav--entry">
                {isAuth ? (
                  <Link to="/admin">Admin</Link>
                ) : (
                  <Link
                    to={`/login?callbackUrl=${base64.encode(
                      constants.ADMIN_URL
                    )}`}
                  >
                    Login
                  </Link>
                )}
              </div>
              <div className="footer__metaNav--entry">
                <a href="#start">Go to top</a>
              </div>
            </nav>
          </div>
          <div className="footer__socialIcons">
            <AppleMusicIcon onclick={clickSocialIcon} />
            <TwitterIcon onclick={clickSocialIcon} />
            <InstagramIcon onclick={clickSocialIcon} />
          </div>
          <div className="footer__copy">
            <div className="u-seperator copyText" />
            <p className="footer__copy--text">
              &copy; Copyright and Design by Joel Mühlena{" "}
              {new Date().getFullYear()}
            </p>
          </div>
        </footer>
      ) : null}
    </Fragment>
  );
};
